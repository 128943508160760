import "./tquiz.css";
import Card from "../../Components/cards/form-cards";
import { ProgressBar } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { questions } from "../../datas";
import { postQuizAnswer } from "../../services/tquiz";

function Correct(props: any) {
  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <div className="m-2">
          <div className="mb-2">
            <img alt="star-img" src="/assets/icons/correct-icon.svg" />
          </div>
          <p className="bold">Correct!</p>
          <p>
            {props?.answer} is the correct answer for {props?.question}
          </p>
          <button
            onClick={() => props.nextQuestion(false)}
            className="btn btn-primary mt-4"
          >
            Continue
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function InCorrect(props: any) {
  return (
    <Modal
      {...props}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <div className="m-2">
          <div className="mb-2">
            <img alt="star-img" src="/assets/icons/correct-icon.svg" />
          </div>
          <p className="bold">Not Correct!</p>
          <p>
            {props?.answer} is the correct answer for {props?.question}
          </p>
          <button
            onClick={() => props.nextQuestion(false)}
            className="btn btn-primary mt-4"
          >
            Continue
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function NoAnswer(props: any) {
  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <div className="m-2">
          <div className="mb-2">
            <img alt="star-img" src="/assets/icons/correct-icon.svg" />
          </div>
          <p className="bold">No Answer!</p>
          <p>No answer was selected in the quiz</p>
          <button
            onClick={() => props.onHide()}
            className="btn btn-primary mt-4"
          >
            Continue
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Result(props: any) {
  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <div className="m-2">
          <div className="mb-2">
            <img
              alt="star-img"
              width="100%"
              src="/assets/images/result-img.svg"
            />
          </div>
          <div className="scores-card">
            <div className="scores">
              <div className="title-1">
                {" "}
                <p>Your Score</p>
              </div>{" "}
              <p className="time-text">{props.result}%</p>
            </div>
            <div className="scores">
              <div className="title-2">
                <p>Your Time</p>
              </div>{" "}
              <p className="time-text">00:00</p>
            </div>
            <div className="scores">
              <div className="title-3">
                <p>Top Score</p>
              </div>
              <p className="time-text">100%</p>
            </div>
          </div>
          <p className="performance-text mt-4">
            You performed better than 19% of users.
          </p>

          <div className="d-flex justify-content-between mt-4">
            <button
              className="btn btn-secondary w-50 btn-pri"
              onClick={props.goHome}
            >
              Home
            </button>
            <button
              className="btn btn-primary w-50 btn-pri"
              onClick={props.shareResult}
            >
              Share Results
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const OracleMode = ({ start, setStart, backHome, data }: any) => {
  const [selected, setSelected] = useState<any>({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showCorrectModal, setShowCorrectModal] = useState(false);
  const [showInCorrectModal, setShowInCorrectModal] = useState(false);
  const [showNoCorrectModal, setShowNoCorrectModal] = useState(false);
  const [showResult, setShowResult] = useState(false);
  let [correct, setCorrect] = useState(0);
  const [answer, setAnswer] = useState();

  const minutes = 0;
  let [seconds, setSeconds] = useState(30);
  const [answerList, setAnswerList] = useState<any>([]);

  const [progress, setProgress] = useState(0);

  const shareResult = async () => {
    await alert("Shared");
    await setShowResult(false);
    await backHome();
  };

  const goHome = () => {
    setShowResult(false);
    backHome();
  };

  const setAnswers = async () => {
    await setAnswerList([
      ...answerList,
      {
        question: data[currentQuestion]?.question,
        selectedAnswer: selected?.answer,
        minutes,
        seconds,
      },
    ]);
    const currentQuestionData = data[currentQuestion]; // Access the current question data

    // Extract the question, answer, and options from currentQuestionData
    const { question, answer, option_one, option_two, option_three } =
      currentQuestionData;

    // Map the answer property to the correct option value
    const options: any = {
      option_one,
      option_two,
      option_three,
    };
    setAnswer(options[answer]);
    if (!selected.answer) {
      await setShowNoCorrectModal(true);
    } else if (selected?.answer === data[currentQuestion]?.answer) {
      setCorrect(correct + 1);
      await setShowCorrectModal(true);
    } else {
      await setShowInCorrectModal(true);
    }
    await setSelected({});
  };

  const nextQuestion = (notAnswered: boolean) => {
    if (currentQuestion + 1 >= data?.length) {
      setShowCorrectModal(false);
      setShowInCorrectModal(notAnswered ? true : false);
      setProgress(100);
      setStart();
      submit();
      setShowResult(true);
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setShowCorrectModal(false);
      setShowInCorrectModal(notAnswered ? true : false);
      setSeconds(30);
      setProgress(((currentQuestion + 1) / data?.length) * 100);
    }
  };

  const NoAswe = () => {
    if (currentQuestion + 1 >= data?.length) {
      setShowCorrectModal(false);
      setShowNoCorrectModal(true);
      setProgress(100);
      setAnswers();
      setStart();
      setSeconds(30);
      submit();
      setShowResult(true);
      return;
    } else {
      setAnswers();
      setShowNoCorrectModal(true);
      setProgress(((currentQuestion + 1) / data?.length) * 100);
      setCurrentQuestion(currentQuestion + 1);
      setSeconds(30);
      setStart();
    }
  };

  useEffect(() => {
    if (!start) return;
    const interval = setInterval(() => {
      if (seconds === 0) {
        NoAswe();
      } else {
        setSeconds(seconds--);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [seconds, start]);

  const submit = async () => {
    const payload = {
      type: "oracle",
      time: `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`,
      score: ((correct / data?.length) * 100).toFixed(2),
    };
    await postQuizAnswer(payload);
  };

  return (
    <div className="">
      <h2 className="quiz-title mt-2">Oracle Quiz</h2>
      <div className="mt-4 quiz-container">
        <Card>
          {data.length ? (
            <>
              <ProgressBar
                now={progress}
                className="mb-5 progress-bars"
                variant="#D88C9A"
              />

              <div className="d-flex justify-content-between ">
                <h3>Question {currentQuestion + 1}</h3>{" "}
                <h2 className="time">
                  {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </h2>
              </div>
              <p>{data.length && data[currentQuestion].question}</p>
              <div className="row">
                <div
                  className={
                    selected.answer === "option_one"
                      ? "col-6 option option-selected"
                      : "col-6 option"
                  }
                  onClick={() => {
                    setSelected({
                      answer: "option_one",
                      value: data[currentQuestion]?.option_one,
                    });
                  }}
                >
                  <p> {data[currentQuestion]?.option_one}</p>
                </div>
                <div
                  className={
                    selected.answer === "option_two"
                      ? "col-6 option option-selected"
                      : "col-6 option"
                  }
                  onClick={() => {
                    setSelected({
                      answer: "option_two",
                      value: data[currentQuestion]?.option_two,
                    });
                  }}
                >
                  <p> {data[currentQuestion]?.option_two}</p>
                </div>
                <div
                  className={
                    selected.answer === "option_three"
                      ? "col-6 option option-selected"
                      : "col-6 option"
                  }
                  onClick={() => {
                    setSelected({
                      answer: "option_three",
                      value: data[currentQuestion]?.option_three,
                    });
                  }}
                >
                  <p> {data[currentQuestion]?.option_three}</p>
                </div>
                {/* <div
                  className={
                    selected.answer === data[currentQuestion]?.answer
                      ? "col-6 option option-selected"
                      : "col-6 option"
                  }
                  onClick={() => {
                    setSelected({
                      status: true,
                      answer: data[currentQuestion]?.answer,
                    });
                  }}
                >
                  <p> {data[currentQuestion]?.answer}</p>
                </div> */}
              </div>

              <button
                onClick={() => {
                  if (!selected?.answer)
                    return alert("Please select an option");
                  setAnswers();
                }}
                className="btn btn-primary mt-4"
                disabled={data?.length === answerList.length}
              >
                Continue
              </button>
            </>
          ) : (
            <>
              <center>
                <h1>Quiz is empty</h1>
              </center>
            </>
          )}
        </Card>
      </div>
      <Correct
        show={showCorrectModal}
        question={data[currentQuestion]?.question}
        answer={answer}
        nextQuestion={nextQuestion}
        onHide={() => {
          setShowCorrectModal(false);
        }}
      />
      <InCorrect
        show={showInCorrectModal}
        question={data[currentQuestion]?.question}
        answer={answer}
        nextQuestion={nextQuestion}
        onHide={() => {
          setShowInCorrectModal(false);
        }}
      />
      <NoAnswer
        show={showNoCorrectModal}
        nextQuestion={nextQuestion}
        onHide={() => {
          if (data.length === answerList.length) {
            setShowNoCorrectModal(false);
          } else {
            setShowNoCorrectModal(false);
            setStart();
          }
        }}
      />
      <Result
        show={showResult}
        goHome={goHome}
        minute={minutes.toString().padStart(2, "0")}
        seconds={seconds.toString().padStart(2, "0")}
        result={((correct / data?.length) * 100).toFixed(2)}
        shareResult={shareResult}
        onHide={() => setShowResult(false)}
      />
    </div>
  );
};

export default OracleMode;
