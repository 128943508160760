import axios from "axios";
import Urls from "./utilities";
import { toast } from "react-toastify";
import { questionnaire } from "../interface/news.interface";
import { encryptReqBody } from "./encrypt";
import { PUBLIC_KEY } from "./encrypt";

export const getQuiz = async () => {
  const token = localStorage.getItem("token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true,
      "x-api-key": PUBLIC_KEY ?? "",

    },
  };
  return axios
    .get(Urls.api_url + "/quizzes/questionnaire/" + 10, options)
    .then((response: any | questionnaire) => {
      return response.data;
    })
    .catch((error: any) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const postQuizAnswer = async (data: any) => {
  const token = localStorage.getItem("token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true,
      "x-api-key": PUBLIC_KEY ?? "",

    },
  };
  return axios
    .post(Urls.api_url + "/quizzes/answer", encryptReqBody(data), options)
    .then((response: any) => {
      return "success";
    })
    .catch((error: any) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const PostQuiz = async (data: any) => {
  const token = localStorage.getItem("token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true,
      "x-api-key": PUBLIC_KEY ?? "",

    },
  };
  return axios
    .post(Urls.api_url + "/quizzes/create", encryptReqBody(data), options)
    .then((response: any) => {
      return toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    })
    .catch((error: any) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};
