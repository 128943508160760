import { signUp } from "../interface/news.interface";

export const validateSignUpData = async (data: signUp) => {
  if (data.first_name === "") return "first_name";
  if (data.last_name === "") return "last_name";
  if (data.middle_name === "") return "middle_name";
  if (data.phone === "") return "phone";
  if (data.email === "") return "email";
  if (data.occupation === "") return "occupation";
  if (data.nationality === "") return "nationality";
  if (data.password === "") return "password";
  if (data.address === "") return "address";
  if (data.city === "") return "city";
  if (data.state === "") return "state";
  if (data.postal_code === "") return "postal_code";
  if (data.country === "") return "country";
  return "success";
};
