import { Container, Form, Nav, Navbar } from "react-bootstrap";
import SeachInput from "../../forms/search";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./navbar.css";
import { useState, useEffect } from "react";
import { isLoggedIn, logOut } from "../../../services/auth";

const Navbars = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [status, setStatus] = useState(false);

  const checkStatus = async () => {
    const status = await isLoggedIn();
    if (!status) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  };

  const logOff = async () => {
    await logOut();
    navigate("/login");
  };
  useEffect(() => {
    checkStatus();
  });

  const nav_links = [
    {
      name: "TNews",
      active_image: "/assets/icons/news-active-icon.svg",
      image: "/assets/icons/news-icon.svg",
      path: "/tnews",
      active: pathname === "/tnews" ? true : false,
    },
    {
      name: "TCalc",
      active_image: "/assets/icons/calc-active-icon.svg",
      image: "/assets/icons/calc-icon.svg",
      path: "/tcalc",
      active: pathname === "/tcalc" ? true : false,
    },
    {
      name: "TQuiz",
      active_image: "/assets/icons/quiz-active-icon.svg",
      image: "/assets/icons/quiz-icon.svg",
      path: "/tquiz",
      active: pathname === "/tquiz" ? true : false,
    },
  ];

  return (
    <Navbar className="navbar" expand="lg">
      <Container fluid className="inner-container">
        <Navbar.Brand href="/">
          <img
            src="/assets/images/tbook.png"
            alt="Logo"
            width="47.78"
            height="45"
            className="d-inline-block align-text-top border-10"
          />
        </Navbar.Brand>
        <Navbar.Toggle className="shadow-none" aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 align-items-center w-100"
            navbarScroll
          >
            {nav_links.map((nav) => (
              <div
                className={
                  nav.active
                    ? "active-nav-link d-flex nav-text align-items-center nav-link justify-content-evenly"
                    : "text-secondary d-flex nav-text align-items-center nav-link justify-content-evenly"
                }
                key={nav.name}
              >
                <img
                  alt="img"
                  src={nav.active ? nav.active_image : nav.image}
                  className="nav-icon"
                />
                <Link
                  className={
                    nav.active
                      ? "text-primary text-decoration-none"
                      : "text-secondary text-decoration-none"
                  }
                  to={nav.path}
                >
                  {nav.name}
                </Link>
              </div>
            ))}
            <Form className="ml-lg-3">
              <SeachInput
                onChange={() => {
                  console.log("text");
                }}
                type="search"
                placeholder="Search"
                className=""
              />
            </Form>
          </Nav>
          <div className="d-flex justify-content-center align-items-center justify-content-between w-18">
            {status ? (
              <>
                {" "}
                <p
                  className="text-secondary text-decoration-none mt-3"
                  onClick={() => {
                    logOff();
                  }}
                >
                  Logout
                </p>
                <Link
                  className="text-decoration-none post-news-button"
                  to={"/upload-news"}
                >
                  <button className="btn-nav">Upload News</button>
                </Link>
              </>
            ) : (
              <>
                {" "}
                <Link
                  className="text-secondary text-decoration-none "
                  to="/login"
                >
                  Login
                </Link>
                <Link className="btn-nav" to="/sign-up">
                  <p className="mt-1">Sign Up</p>
                </Link>
              </>
            )}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navbars;
