import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { comments, viewFormProps } from "../../interface/news.interface";
import { getCommentById, postComment } from "../../services/news";
import "./card.css";
import { isLoggedIn } from "../../services/auth";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

function CommentsModal(props: any) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="text-end">
        <span
          className="material-symbols-outlined cursor-pointer"
          onClick={props?.onHide}
        >
          close
        </span>
      </div>
      <Modal.Body className="modal-container">
        <div className="m-1">
          <strong className="">Comments</strong>
          {props?.comments.length ? (
            props.comments?.map((i: any) => (
              <div className="comments border p-1 rounded">
                <p>{i?.message || i?.comment}</p>
                <small>
                  {moment(i.createdAt).format("dddd DD MMMM, YYYY")}
                </small>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

const DetailCard = ({
  date,
  title,
  text,
  image,
  liked,
  disliked,
  dislikePost,
  likePost,
  comments,
  image_url,
  content,
  createdAt,
  created_no,
  created_on,
  active,
  is_deleted,
  updatedAt,
  loved_by,
  _id,
  ...restProps
}: viewFormProps) => {
  const [openComment, setOpenComment] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [newsComment, setNewsComent] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  //const id = queryParams.get("news-id");
  const { id, newsTitle } = useParams();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [comment, setComment] = useState<comments>({
    model_id: id,
    message: "",
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleReset = () => {
    if (textAreaRef.current) textAreaRef.current.value = "";
  };
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const sendComment = async () => {
    setLoading(true);
    const status = await isLoggedIn();
    if (!status) {
      setLoading(false);
      return toast.error("You are not logged in", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (!comment.message) return setError(true);
    const response = postComment(comment);
    setLoading(false);
    handleReset();
    return response;
  };
  const allComments = async () => {
    const response = await getCommentById(id);
    setNewsComent(response.data);
  };
  const currentUrl = window.location.href.replace(/ /g, "-");
  const handleCopyLink = () => {
    if (navigator.share) {
      navigator
        .share({ url: currentUrl })
        .then(() => {
          toast.success("Link copied to clipboard!");
        })
        .catch((error) => {
          console.error("Error sharing video:", error);
        });
    } else {
      // Fallback: Copy video URL to clipboard
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          toast.success("Link copied to clipboard!");
        })
        .catch((error) => {
          console.error("Error copying video URL:", error);
        });
    }
  };

  useEffect(() => {
    allComments();
  }, [id, newsComment]);
  return (
    <div className={"card border-0"} {...restProps}>
      <ToastContainer />
      {loading && (
        <>
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
          <ToastContainer />
        </>
      )}

      <div className={"w-100 p-2"}>
        <img
          className="max-height-450 w-100 m-1"
          alt="img"
          src={image || image_url || ""}
        />
      </div>

      <div className="p-3">
        <p className="card-date">
          {createdAt
            ? moment(createdAt).format("dddd DD MMMM, YYYY")
            : moment(created_on).format("dddd DD MMMM, YYYY")}
        </p>
        <h4 className="card-title">{title}</h4>
        <div
          className="card-text"
          dangerouslySetInnerHTML={{
            __html: text || content,
          }}
        ></div>
      </div>
      <div className="new-feeds m-3">
        <p className="d-flex align-items-center">
          <span
            className={
              liked
                ? "material-symbols-outlined liked cursor-pointer"
                : "material-symbols-outlined cursor-pointer"
            }
            onClick={likePost}
          >
            thumb_up
          </span>{" "}
          <span className="likes">{loved_by >= 0 ? loved_by : 1} likes</span>
        </p>{" "}
        <p className="d-flex align-items-center">
          <span
            className={
              disliked
                ? "material-symbols-outlined disliked cursor-pointer"
                : "material-symbols-outlined cursor-pointer"
            }
            onClick={dislikePost}
          >
            thumb_down
          </span>{" "}
        </p>{" "}
        <p className="d-flex align-items-center">
          <span
            className="material-symbols-outlined cursor-pointer"
            onClick={() => setOpenComment(true)}
          >
            mode_comment
          </span>
          <span
            className="likes cursor-pointer"
            onClick={() => setCommentModal(true)}
          >
            {newsComment?.length} comments
          </span>
        </p>{" "}
        <p
          className="d-flex align-items-center"
          onClick={() => {
            handleCopyLink();
          }}
          style={{ cursor: "pointer" }}
        >
          <span className="material-symbols-outlined cursor-pointer">
            share
          </span>
          <span className="likes">1 share</span>
        </p>{" "}
      </div>

      {openComment && (
        <div className="p-3 text-end">
          <span
            className="material-symbols-outlined cursor-pointer"
            onClick={() => setOpenComment(false)}
          >
            close
          </span>
          <textarea
            ref={textAreaRef}
            style={{ borderColor: error ? "red" : "black" }}
            className="img-input form-control mb-3 shadow-none"
            placeholder="Comment...."
            onChange={(e) => {
              setComment((comment) => ({
                ...comment,
                message: e.target.value,
              }));
            }}
          ></textarea>
          <button
            className="btn btn-primary"
            onClick={() => {
              sendComment();
            }}
          >
            Post Comment
          </button>
        </div>
      )}

      <CommentsModal
        show={commentModal}
        comments={newsComment || comment || []}
        onHide={() => setCommentModal(false)}
      />
    </div>
  );
};

export default DetailCard;
