import "./login.css";
import Input from "../../Components/forms/form";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { isLoggedIn, signUpRequest } from "../../services/auth";
import { signUp } from "../../interface/news.interface";
import { Otp } from "./verifyEmail";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { toast, ToastContainer } from "react-toastify";

const SignUp = () => {
  const navigate = useNavigate();
  const [errorName, setErrorName] = useState<any>("");
  const [data, setData] = useState<signUp>({
    account_type: "individual",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone: "",
    occupation: "",
    nationality: "",
    password: "",
    address: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
  });
  const [settingOtp, setSettingOtp] = useState(false);
  const handleDateChange = (e: any) => {
    setData((prevDetails) => ({
      ...prevDetails,
      [e.target.id]: e.target.value,
    }));
  };
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  //check if the user is logged in
  const isActiveUser = async () => {
    const status = await isLoggedIn();
    if (status) navigate("/tnews");
  };

  //sign up request
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const response: string | any = await signUpRequest(data);
    await setLoading(false);
    if (response === "success") setSettingOtp(true);
    if (response.length < 13 && response !== "success") {
      setErrorName(response);
      return toast.error(`${response}  is missing`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    return response;
  };

  useEffect(() => {
    isActiveUser();
  });
  return (
    <>
      {settingOtp ? (
        <>
          <Otp email={data.email} otp={""} />{" "}
        </>
      ) : (
        <>
          <div className="sign-up mb-5">
            {loading && (
              <>
                <Backdrop className={classes.backdrop} open>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </>
            )}
            <ToastContainer />
            <img
              alt="img"
              src="/assets/images/tbook.png"
              width="47.78"
              height="45"
              className="border-10 "
            />
            <h3 className="mt-4">Create an Account</h3>
            <p className="mb-3 text-secondary">
              Create an account to get started.
            </p>
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="First Name"
              type="text"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"first_name"}
              value={data.first_name}
              required={true}
            />
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="Middle Name"
              type="text"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"middle_name"}
              value={data.middle_name}
              required={true}
            />
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="Last Name"
              type="text"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"last_name"}
              value={data.last_name}
              required={true}
            />
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="Phone"
              type="number"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"phone"}
              value={data.phone}
              required={true}
            />
            <p className="not-send">We will not send you unneccessary emails</p>
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="Email"
              type="email"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"email"}
              value={data.email}
              required={true}
            />
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="Occupation"
              type="text"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"occupation"}
              value={data.occupation}
              required={true}
            />
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="Nationality"
              type="text"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"nationality"}
              value={data.nationality}
              required={true}
            />
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="Address"
              type="text"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"address"}
              value={data.address}
              required={true}
            />
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="City"
              type="text"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"city"}
              value={data.city}
              required={true}
            />
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="State"
              type="text"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"state"}
              value={data.state}
              required={true}
            />
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="Postal Code"
              type="text"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"postal_code"}
              value={data.postal_code}
              required={true}
            />
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="Country"
              type="text"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"country"}
              value={data.country}
              required={true}
            />
            <Input
              showNaira={false}
              showQuestion={false}
              placeholder="Password"
              type="password"
              onChange={(e) => {
                handleDateChange(e);
              }}
              ref={undefined}
              id={"password"}
              value={data.password}
              required={true}
            />
            <div className="d-flex justify-content-between mt-4">
              <div className="d-flex w-100">
                <input
                  className="form-check-input shadow-none"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <p className="ml-5">I agree to the Terms & Conditions</p>
              </div>
            </div>
            <button
              className="btn btn-primary mt-4"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
            <div className="text-center mt-3">
              <p>
                <span className="text-secondary">
                  Already have an account?{" "}
                </span>{" "}
                <Link className="auth-link" to="/login">
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SignUp;
