import { ToastContainer } from "react-toastify";
import Page from "../../Components/pages/page";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../Components/forms/form";
import { useRef, useState } from "react";
import template from "../../files/meme.jpeg";
import { PostQuiz } from "../../services/tquiz";

const CreateQuiz = () => {
  const [loading, setLoading] = useState(false);
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const uploadQUiz = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("quiz_file", file);
    const post = await PostQuiz(formdata);
    setLoading(false);
    return post;
  };

  function handleInputChange(e: any) {
    setName(e.target.value);
  }
  const onHandleFiles = (e: any) => {
    let name = e.target.files[0];
    setFile(name);
  };
  return (
    <Page>
      <div className="upload-quiz">
        <ToastContainer />
        {loading && (
          <>
            <Backdrop className={classes.backdrop} open>
              <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer />
          </>
        )}
        <div className="d-flex">
          {" "}
          <h3 className="mt-4 mb-5 float-left">Upload Quiz</h3>
          <a
            style={{ float: "left" }}
            href={
              "https://docs.google.com/spreadsheets/d/1OcLICJCnEk50AanoIQv_t5AcKwjJYeDl/edit?usp=share_link&ouid=114415697377745159681&rtpof=true&sd=true"
            }
            download="template.xlsx"
            className="mt-2 mb-5 float-right color-green text-decoration-none link-dark"
          >
            <span className="">Download Template</span>
          </a>
        </div>

        <label className="mb-2">Name</label>
        <Input
          ref={firstInputRef}
          showNaira={false}
          showQuestion={false}
          placeholder="Select Name"
          type="text"
          onChange={(e: any) => handleInputChange(e)}
          id={"title"}
          value={name}
          required={false}
        />
        <label className="file-upload">
          <input
            type="file"
            id="file"
            aria-label="File browser example"
            onChange={(e) => {
              onHandleFiles(e);
            }}
          />
        </label>

        <button
          type="submit"
          className="btn btn-primary mt-4"
          onClick={(e) => {
            uploadQUiz(e);
          }}
        >
          Upload
        </button>
      </div>
    </Page>
  );
};

export default CreateQuiz;
