import { deduction } from "../interface/news.interface";
//this function handles the number formatting
export const commafy = (num: number) => {
  let str = num.toString().split(".");
  if (str[0] == "0") {
    str[0] = str[0].substr(1);
  }

  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  return str.join(".");
};

//this function handles the calculation of Consolidated Relief Allowance (Monthly)
//Consolidated Relief Allowance (CRA) is a relief granted to you by law and calculated as 20% of your Gross Income + the higher of ₦200,000 or 1% of your Gross Income.
export const calculateConsolidated = (grossIncome: any) => {
  const firstlayer = 0.2 * parseFloat(grossIncome);
  let secondlayer;
  if (0.01 * parseFloat(grossIncome) > 200000 / 12) {
    secondlayer = firstlayer + 0.01 * parseFloat(grossIncome);
    secondlayer = Math.round((secondlayer + Number.EPSILON) * 100) / 100;
    return commafy(secondlayer);
  } else {
    secondlayer = firstlayer + 200000 / 12;
    secondlayer = Math.round((secondlayer + Number.EPSILON) * 100) / 100;
    return commafy(secondlayer);
  }
};

//this function handles the  calculation ofConsolidated Relief Allowance(Yearly)
//Consolidated Relief Allowance (CRA) is a relief granted to you by law and calculated as 20% of your Gross Income + the higher of ₦200,000 or 1% of your Gross Income.
export const calculateYearlyConsolidated = (grossIncome: any) => {
  const firstlayer = 0.2 * parseFloat(grossIncome);
  let secondlayer;
  if (0.01 * parseFloat(grossIncome) > 200000) {
    secondlayer = firstlayer + 0.01 * parseFloat(grossIncome);
    secondlayer = Math.round((secondlayer + Number.EPSILON) * 100) / 100;
    return commafy(secondlayer);
  } else {
    secondlayer = firstlayer + 200000;
    secondlayer = Math.round((secondlayer + Number.EPSILON) * 100) / 100;
    return commafy(secondlayer);
  }
};

//this function handles the calculation of deduction(Monthly)
export const calculateDeductions = (data: deduction) => {
  const add =
    parseFloat(data.pensionDeduction) +
    parseFloat(data.nhfContribution) +
    parseFloat(data.lifeInsurance) +
    parseFloat(data.nhisContribution) +
    parseFloat(data.mortgageInterest);

  const final = add + parseFloat(calculateConsolidated(data.grossIncome));

  return commafy(Math.round((final + Number.EPSILON) * 100) / 100);
};

//this function handles the calculation of deduction(Yearly)
export const calculateYearlyDeductions = (data: deduction) => {
  const add =
    parseFloat(data.pensionDeduction) +
    parseFloat(data.nhfContribution) +
    parseFloat(data.lifeInsurance) +
    parseFloat(data.nhisContribution) +
    parseFloat(data.mortgageInterest);

  const final = add + parseFloat(calculateYearlyConsolidated(data.grossIncome));
  return commafy(Math.round((final + Number.EPSILON) * 100) / 100);
};

//this function handles the calculation of taxable income(Monthly)
export const calculateTaxableIncome = (data: deduction) => {
  const remove =
    parseFloat(data.grossIncome) - parseFloat(calculateDeductions(data));
  return commafy(Math.round((remove + Number.EPSILON) * 100) / 100);
};

//this function handles thecalculation of taxable income(Yearly)
export const calculateYearlyTaxableIncome = (data: deduction) => {
  const remove =
    parseFloat(data.grossIncome) - parseFloat(calculateYearlyDeductions(data));
  return commafy(Math.round((remove + Number.EPSILON) * 100) / 100);
};

//this function handles the calculation of Minimum tax(Monthly)
export const calculateMinimumTax = (grossIncome: Number | any) => {
  const minimum = 0.01 * parseFloat(grossIncome);
  return commafy(Math.round((minimum + Number.EPSILON) * 100) / 100);
};

//this function handles the calculation of Minimum tax(Yearly)
export const calculateYearlyMinimumTax = (grossIncome: Number | any) => {
  const minimum = 0.01 * parseFloat(grossIncome);
  return commafy(Math.round((minimum + Number.EPSILON) * 100) / 100);
};

//this function handles the calculation of Minimum Tax Due(Yearly)
//Minimum Tax is due when calculated tax is less than 1% of your Gross Income.
export const taxDue = (data: deduction) => {
  if (
    parseFloat(calculateMinimumTax(data.grossIncome)) <
      parseFloat(calculateTaxableIncome(data)) ||
    parseFloat(calculateYearlyMinimumTax(data.grossIncome)) <
      parseFloat(calculateYearlyTaxableIncome(data))
  ) {
    return "No";
  } else if (
    parseFloat(calculateMinimumTax(data.grossIncome)) <
      parseFloat(calculateTaxableIncome(data)) ||
    parseFloat(calculateYearlyMinimumTax(data.grossIncome)) >
      parseFloat(calculateYearlyTaxableIncome(data))
  ) {
    return "Yes";
  }
};
