import Page from "../../Components/pages/page";
import "./tcalc.css";
import Taxable from "./taxable-form";
import NoneTaxable from "./non-taxable-form";
import Estimate from "./estimate";
import { useEffect, useState } from "react";
import { deduction } from "../../interface/news.interface";

const Tcalc = () => {
  const [activeCalc, setActiveCalc] = useState("non-taxable");
  const [done, setDone] = useState(false);
  const [timePeriod, setTimePeriod] = useState("monthly");
  const [nonTaxable, setNoneTaxable] = useState<deduction>({
    timePeriod: timePeriod,
    grossIncome: 0,
    pensionDeduction: 0,
    nhfContribution: 0,
    lifeInsurance: 0,
    nhisContribution: 0,
    mortgageInterest: 0,
  });
  const [dataFormatting, setDataFormatting] = useState<deduction>({
    timePeriod: timePeriod,
    grossIncome: 0,
    pensionDeduction: 0,
    nhfContribution: 0,
    lifeInsurance: 0,
    nhisContribution: 0,
    mortgageInterest: 0,
  });

  const [taxable, setTaxable] = useState({
    time_period: 0,
    salary: 0,
    commission: 0,
    trade_income: 0,
    pension_received: 0,
    annuity: 0,
    gratuities: 0,
    foreign_income: 0,
    dividend: 0,
    interest: 0,
    rent: 0,
    others: 0,
  });

  const [isMonthly, setIsMonthly] = useState<boolean>();
  const [estimate, setEstimate] = useState("");

  const onChangeTaxable = (type: string, data: any) => {
    setEstimate("taxable");
    if (type === "time_period") {
      setTaxable({ ...taxable, time_period: data });
    } else if (type === "salary") {
      setTaxable({ ...taxable, salary: data });
    } else if (type === "commission") {
      setTaxable({ ...taxable, commission: data });
    } else if (type === "trade_income") {
      setTaxable({ ...taxable, trade_income: data });
    } else if (type === "pension_received") {
      setTaxable({ ...taxable, pension_received: data });
    } else if (type === "annuity") {
      setTaxable({ ...taxable, annuity: data });
    } else if (type === "gratuities") {
      setTaxable({ ...taxable, gratuities: data });
    } else if (type === "foreign_income") {
      setTaxable({ ...taxable, foreign_income: data });
    } else if (type === "dividend") {
      setTaxable({ ...taxable, dividend: data });
    } else if (type === "interest") {
      setTaxable({ ...taxable, interest: data });
    } else if (type === "rent") {
      setTaxable({ ...taxable, rent: data });
    } else if (type === "others") {
      setTaxable({ ...taxable, others: data });
    }
  };

  const onChangeNonTaxable = (type: string, data: any) => {
    setEstimate("non_taxable");
    if (type === "salary") {
      const numericValue = Number(data.replace(/[^0-9]/g, ""));
      const formattedValue = numericValue.toLocaleString();
      setNoneTaxable({ ...nonTaxable, grossIncome: formattedValue });
      setDataFormatting({ ...dataFormatting, grossIncome: numericValue });
    } else if (type === "pension_deduction") {
      const numericValue = Number(data.replace(/[^0-9]/g, ""));
      const formattedValue = numericValue.toLocaleString();
      console.log(numericValue);
      setNoneTaxable({ ...nonTaxable, pensionDeduction: formattedValue });
      setDataFormatting({ ...dataFormatting, pensionDeduction: numericValue });
    } else if (type === "nhf_contribution") {
      const numericValue = Number(data.replace(/[^0-9]/g, ""));
      const formattedValue = numericValue.toLocaleString();
      setNoneTaxable({ ...nonTaxable, nhfContribution: formattedValue });
      setDataFormatting({ ...dataFormatting, nhfContribution: numericValue });
    } else if (type === "life_insurance_premium") {
      const numericValue = Number(data.replace(/[^0-9]/g, ""));
      const formattedValue = numericValue.toLocaleString();
      console.log(numericValue);
      setNoneTaxable({ ...nonTaxable, lifeInsurance: formattedValue });
      setDataFormatting({ ...dataFormatting, lifeInsurance: numericValue });
    } else if (type === "nhis_contribution") {
      const numericValue = Number(data.replace(/[^0-9]/g, ""));
      const formattedValue = numericValue.toLocaleString();
      console.log(numericValue);
      setNoneTaxable({ ...nonTaxable, nhisContribution: formattedValue });
      setDataFormatting({ ...dataFormatting, nhisContribution: numericValue });
    } else if (type === "interest_on_mortgage_loan") {
      const numericValue = Number(data.replace(/[^0-9]/g, ""));
      const formattedValue = numericValue.toLocaleString();
      console.log(numericValue);
      setNoneTaxable({ ...nonTaxable, mortgageInterest: formattedValue });
      setDataFormatting({ ...dataFormatting, mortgageInterest: numericValue });
    } else if (type === "time_period") {
      setTimePeriod(data);
    }
  };
  const finishCal = () => {
    setDone(!done);
  };
  useEffect(() => {
    console.log(dataFormatting);
    if (timePeriod) return;
    if (timePeriod.toLowerCase() === "monthly") {
      setIsMonthly(true);
    } else {
      setIsMonthly(false);
    }
  });

  return (
    <Page>
      <div className="calc-list">
        <h2 className="calc-title mt-2">TCalc</h2>
        <div>
          {!done && (
            <div className="tab d-flex justify-content-between">
              {/* <p
                className={activeCalc === "taxable" ? "active" : ""}
                onClick={() => setActiveCalc("taxable")}
              >
                Taxable
              </p> */}
              <p
                className={activeCalc === "non-taxable" ? "active" : ""}
                onClick={() => setActiveCalc("non-taxable")}
              >
                Non-Taxable Income
              </p>
            </div>
          )}
          {activeCalc === "taxable" && !done && (
            <Taxable
              complete={finishCal}
              taxable={taxable}
              onChange={onChangeTaxable}
            />
          )}
          {activeCalc === "non-taxable" && !done && (
            <NoneTaxable
              complete={finishCal}
              onChange={onChangeNonTaxable}
              values={nonTaxable}
            />
          )}
          {done && (
            <Estimate
              complete={finishCal}
              taxable={taxable}
              nonTaxable={dataFormatting}
              action={estimate}
              isMonthly={isMonthly}
              timePeriod={timePeriod}
            />
          )}
        </div>
      </div>
    </Page>
  );
};

export default Tcalc;
