import axios from "axios";
import Urls from "./utilities";
import { encryptReqBody } from "./encrypt";
import { toast } from "react-toastify";
import {
  comments,
  likes,
  uploadNewsPayload,
} from "../interface/news.interface";
import { PUBLIC_KEY } from "./encrypt";

export const allNews = async () => {
  return axios
    .get(Urls.api_url + "/posts", {
      headers: {
        "x-api-key": PUBLIC_KEY ?? "",
      }
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.message;
    });
};

export const singleNews = async (id: any) => {
  return axios
    .get(Urls.api_url + "/posts/" + id, {
      headers: {
        "x-api-key": PUBLIC_KEY ?? "",
      }
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.message;
    });
};
export const deleteNews = async (id: any) => {
  const token = localStorage.getItem("token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true,
      "x-api-key": PUBLIC_KEY ?? "",

    },
  };
  return axios
    .delete(Urls.api_url + "/posts/" + id, options)
    .then((response: any) => {
      return toast.success("Post Deleted successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
    })
    .catch((error: any) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const uploadNews = async (data: uploadNewsPayload) => {
  const token = localStorage.getItem("token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true,
      "x-api-key": PUBLIC_KEY ?? "",

    },
  };
  return axios
    .post(Urls.api_url + "/posts", data, options)
    .then((response: any) => {
      return toast.success("Post created successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
    })
    .catch((error: any) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const postComment = async (data: comments) => {
  const token = localStorage.getItem("token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true,
      "x-api-key": PUBLIC_KEY ?? "",

    },
  };
  return axios
    .post(Urls.api_url + "/comments", encryptReqBody(data), options)
    .then((response: any) => {
      return toast.success("Comment added successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
    })
    .catch((error: any) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const getCommentById = async (id: any) => {
  return axios
    .get(Urls.api_url + `/comments/${id}`, {
      headers: {
        "x-api-key": PUBLIC_KEY ?? "",
      }
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.message;
    });
};

export const sendLikes = async (data: likes) => {
  const token = localStorage.getItem("token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true,
      "x-api-key": PUBLIC_KEY ?? "",

    },
  };
  return axios
    .post(Urls.api_url + "/posts/action", encryptReqBody(data), options)
    .then((response: any) => {
      return toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    })
    .catch((error: any) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};
export const sendDisLikes = async (data: likes) => {
  const token = localStorage.getItem("token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true,
      "x-api-key": PUBLIC_KEY ?? "",

    },
  };
  return axios
    .post(Urls.api_url + "/posts/action", encryptReqBody(data), options)
    .then((response: any) => {
      return toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    })
    .catch((error: any) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const updatePost = async (id: string, data: uploadNewsPayload) => {
  const token = localStorage.getItem("token");
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true,
      "x-api-key": PUBLIC_KEY ?? "",

    },
  };
  return axios
    .put(Urls.api_url + "/posts/" + id, encryptReqBody(data), options)
    .then((response: any) => {
      return toast.success("Post Updated successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
    })
    .catch((error: any) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};
