import { Navigate, BrowserRouter as Router, useRoutes } from "react-router-dom";
import TNews from "./Pages/tnews";
import TCalc from "./Pages/tcalc";
import TQuiz from "./Pages/tquiz";
import UploadNews from "./Pages/tnews/upload-news";
import Login from "./Pages/login";
import SignUp from "./Pages/sign-up";
import CreateQuiz from "./Pages/tquiz/create-tquiz";
import NewsDetail from "./Pages/tnews/news-detail";

const Routes = () => {
  return useRoutes([
    { path: "/", element: <Navigate to="/tnews" /> },
    { path: "/tnews", element: <TNews /> },
    { path: "/tcalc", element: <TCalc /> },
    { path: "/tquiz", element: <TQuiz /> },
    { path: "/upload-news", element: <UploadNews /> },
    { path: "/news-details/:id/:newsTitle", element: <NewsDetail /> },
    { path: "/login", element: <Login /> },
    { path: "/sign-up", element: <SignUp /> },
    { path: "/upload-quiz", element: <CreateQuiz /> },
  ]);
};

const AppWrapper = () => {
  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default AppWrapper;
