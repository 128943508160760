import "./tnews.css";

const SendFundz = () => {
  return (
    <div className='d-flex'>
      <div className='spaces'></div>
      <div className='send-fundz d-flex justify-content-between'>
        <div className='m-5 send-fundz-sm'>
          <h2>Send funds</h2>
          <h3>Remit taxes</h3>
          <h3>Buy utilities</h3>
        </div>
        <div className='align-self-end img'>
          <img alt='img' src='/assets/images/mobile.svg' />
        </div>
      </div>
    </div>
  );
};

export default SendFundz;
