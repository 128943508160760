import axios from "axios";
import Urls from "./utilities";
import { toast } from "react-toastify";
import { loginPayload, signUp } from "../interface/news.interface";
import { validateSignUpData } from "../helpers/validationSignUp";
import { encryptReqBody } from "./encrypt";
import { PUBLIC_KEY } from "./encrypt";

export const logInRequest = async (loginFormData: loginPayload) => {
  if (loginFormData.email === "" || loginFormData.password === "") {
    return toast.error("Invalid email or password", {
      position: toast.POSITION.TOP_CENTER,
    });
  } else {
    return await axios
      .post(Urls.auth_route + "/auth/login", encryptReqBody(loginFormData), {
        headers: {
          "x-api-key": PUBLIC_KEY ?? "",
        }
      })
      .then((response: any) => {
        //set token to local storage here
        localStorage.setItem("token", response.data.data.token);
        return "success";
      })
      .catch((error: any) => {
        return toast.error("Invalid user credentials!", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }
};

export const isLoggedIn = async () => {
  const token = await localStorage.getItem("token");
  return Boolean(token);
};

export const logOut = async () => {
  const token = await localStorage.removeItem("token");
  return Boolean(token);
};

export const signUpRequest = async (signUpData: signUp) => {
  const validate = await validateSignUpData(signUpData);
  if (validate !== "success") {
    return validate;
  } else {
    return await axios
      .post(Urls.auth_route + "/auth/register", encryptReqBody(signUpData), {
        headers: {
          "x-api-key": PUBLIC_KEY ?? "",
        }
      })
      .then(() => {
        return "success";
      })
      .catch((error: any) => {
        return toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }
};

export const disable2Fa = async (action: string, token: string) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true,
      "x-api-key": PUBLIC_KEY ?? "",

    },
  };
  return await axios
    .post(Urls.auth_route + "/settings/user-2fa", encryptReqBody({ action: action }), options)
    .then(() => {
      return "success";
    })
    .catch((error: any) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};

export const verifyEmail = async (email: string, otp: string) => {
  return await axios
    .post(Urls.auth_route + "/auth/verify-email", encryptReqBody({ email: email, otp: otp }),
      {
        headers: {
          "x-api-key": PUBLIC_KEY ?? "",
        }
      })
    .then((response: any) => {
      //set token to local storage here
      localStorage.setItem("token", response.data.data.user.token);
      const disable = disable2Fa("disable", response.data.data.user.token);
      return disable;
    })
    .catch((error: any) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
};
