export const trending: any = [
  {
    image: "/assets/images/tmb.png",
    title: "The Third Mainland Bridge; an enduring legacy",
    text: "The Third Mainland bridge is Nigeria’s longest bridge and is one of the three bridges linking Lagos mainland and Lagos island. The bridge was originally called the Ibrahim Babangida Bridge, after the former head of state of Nigeria. It was under his rule that the bridge was completed in conjunction with the military government of Lagos State. The bridge spans 11.8km and was constructed in 1990 in response to the need to connect the Lagos island to its mainland.",
    date: "2023-01-23T12:13:11.930Z",
    comments: [
      { comment: "This is very good", date: "2023-01-23T12:13:11.930Z" },
      {
        comment: "Great news and i will love to see more",
        date: "2023-01-23T12:13:11.930Z",
      },
    ],
    _id: "dlsdoomrf0r940rerf49849545j",
  },
  {
    image: "/assets/images/taxes.png",
    title:
      "10 Tips for your 2019 Personal Income Tax Returns Filing in Nigeria",
    text: "The Personal Income Tax (PIT) returns filing season is here again. We have set out in this short piece information which we hope will help you in staying PIT compliant in 2019",
    date: "2023-01-23T12:13:11.930Z",
    comments: [
      { comment: "This is very good", date: "2023-01-23T12:13:11.930Z" },
      {
        comment: "Great news and i will love to see more",
        date: "2023-01-23T12:13:11.930Z",
      },
    ],
    _id: "3983d4r09cj4fr4h98we39e03f",
  },
  {
    image: "/assets/images/nigerian-road.png",
    title:
      "The Nigerian Road Investment Tax Credit Scheme: A Precis of the Incentive",
    text: "The Third Mainland bridge is Nigeria’s longest bridge and is one of the three bridges linking Lagos mainland and Lagos island. The bridge was originally called the Ibrahim Babangida Bridge, after the former head of state of Nigeria. It was under his rule that the bridge was completed in conjunction with the military government of Lagos State. The bridge spans 11.8km and was constructed in 1990 in response to the need to connect the Lagos island to its mainland.",
    date: "2023-01-23T12:13:11.930Z",
    comments: [
      { comment: "This is very good", date: "2023-01-23T12:13:11.930Z" },
      {
        comment: "Great news and i will love to see more",
        date: "2023-01-23T12:13:11.930Z",
      },
    ],
    _id: "3983dh398dj39dj93jd93j983j",
  },
];

export const dummy_news: any = [
  {
    image: "/assets/images/tmb.png",
    title: "The Third Mainland Bridge; an enduring legacy",
    text: "The Third Mainland bridge is Nigeria’s longest bridge and is one of the three bridges linking Lagos mainland and Lagos island. The bridge was originally called the Ibrahim Babangida Bridge, after the former head of state of Nigeria. It was under his rule that the bridge was completed in conjunction with the military government of Lagos State. The bridge spans 11.8km and was constructed in 1990 in response to the need to connect the Lagos island to its mainland.",
    date: "2023-01-23T12:13:11.930Z",
    comments: [
      { comment: "This is very good", date: "2023-01-23T12:13:11.930Z" },
      {
        comment: "Great news and i will love to see more",
        date: "2023-01-23T12:13:11.930Z",
      },
    ],
    _id: "f459848dnr9ef489j984j8949",
  },
  {
    image: "/assets/images/taxes.png",
    title:
      "10 Tips for your 2019 Personal Income Tax Returns Filing in Nigeria",
    text: "The Personal Income Tax (PIT) returns filing season is here again. We have set out in this short piece information which we hope will help you in staying PIT compliant in 2019",
    date: "2023-01-23T12:13:11.930Z",
    comments: [
      { comment: "This is very good", date: "2023-01-23T12:13:11.930Z" },
      {
        comment: "Great news and i will love to see more",
        date: "2023-01-23T12:13:11.930Z",
      },
    ],
    _id: "dncrch48r9hc498fh3ef832hf44",
  },
  {
    image: "/assets/images/nigerian-road.png",
    title:
      "The Nigerian Road Investment Tax Credit Scheme: A Precis of the Incentive",
    text: "The Third Mainland bridge is Nigeria’s longest bridge and is one of the three bridges linking Lagos mainland and Lagos island. The bridge was originally called the Ibrahim Babangida Bridge, after the former head of state of Nigeria. It was under his rule that the bridge was completed in conjunction with the military government of Lagos State. The bridge spans 11.8km and was constructed in 1990 in response to the need to connect the Lagos island to its mainland.",
    date: "2023-01-23T12:13:11.930Z",
    comments: [
      { comment: "This is very good", date: "2023-01-23T12:13:11.930Z" },
      {
        comment: "Great news and i will love to see more",
        date: "2023-01-23T12:13:11.930Z",
      },
    ],
    _id: "cricni9jc4949n4894nd9enenr99",
  },
];

export const article: any = [
  {
    image: "/assets/images/tmb.png",
    title: "The Third Mainland Bridge; an enduring legacy",
    text: "The Third Mainland bridge is Nigeria’s longest bridge and is one of the three bridges linking Lagos mainland and Lagos island. The bridge was originally called the Ibrahim Babangida Bridge, after the former head of state of Nigeria. It was under his rule that the bridge was completed in conjunction with the military government of Lagos State. The bridge spans 11.8km and was constructed in 1990 in response to the need to connect the Lagos island to its mainland.",
    date: "2023-01-23T12:13:11.930Z",
    comments: [
      { comment: "This is very good", date: "2023-01-23T12:13:11.930Z" },
      {
        comment: "Great news and i will love to see more",
        date: "2023-01-23T12:13:11.930Z",
      },
    ],
    _id: "rc94994d493439dj90f4d404904",
  },
  {
    image: "/assets/images/taxes.png",
    title:
      "10 Tips for your 2019 Personal Income Tax Returns Filing in Nigeria",
    text: "The Personal Income Tax (PIT) returns filing season is here again. We have set out in this short piece information which we hope will help you in staying PIT compliant in 2019",
    date: "2023-01-23T12:13:11.930Z",
    comments: [
      { comment: "This is very good", date: "2023-01-23T12:13:11.930Z" },
      {
        comment: "Great news and i will love to see more",
        date: "2023-01-23T12:13:11.930Z",
      },
    ],
    _id: "4rrj4e04905jf9054595j4dnn48",
  },
  {
    image: "/assets/images/nigerian-road.png",
    title:
      "The Nigerian Road Investment Tax Credit Scheme: A Precis of the Incentive",
    text: "The Third Mainland bridge is Nigeria’s longest bridge and is one of the three bridges linking Lagos mainland and Lagos island. The bridge was originally called the Ibrahim Babangida Bridge, after the former head of state of Nigeria. It was under his rule that the bridge was completed in conjunction with the military government of Lagos State. The bridge spans 11.8km and was constructed in 1990 in response to the need to connect the Lagos island to its mainland.",
    date: "2023-01-23T12:13:11.930Z",
    comments: [
      { comment: "This is very good", date: "2023-01-23T12:13:11.930Z" },
      {
        comment: "Great news and i will love to see more",
        date: "2023-01-23T12:13:11.930Z",
      },
    ],
    _id: "mcr4904r09r49049r940jr4j490r",
  },
];

export const questions = [
  {
    question: "What is my name?",
    id: "d387d438",
    options: [
      { option: "Test", answer: false, id: "d309" },
      { option: "Lorem", answer: false, id: "c30934" },
      { option: "Hello World", answer: true, id: "c0434" },
      { option: "Console", answer: false, id: "sd3383" },
    ],
  },

  {
    question: "How old am i?",
    id: "ddcrenh8478",
    options: [
      { option: "20 years", answer: false, id: "v45g59" },
      { option: "21 years", answer: false, id: "kbt494" },
      { option: "22 years", answer: true, id: "t05l4l" },
      { option: "23 years", answer: false, id: "29sd39" },
    ],
  },

  {
    question: "Which food do i like?",
    id: "dcre984893f",
    options: [
      { option: "Rice", answer: false, id: "29388" },
      { option: "Eba", answer: false, id: "192je9" },
      { option: "Semo", answer: true, id: "05494e" },
      { option: "Yam", answer: false, id: "s2ie39" },
    ],
  },
];
