import { useEffect, useState } from "react";
import Card from "../../Components/cards/form-cards";
import numeral from "numeral";
import "./tcalc.css";
import {
  calculateConsolidated,
  calculateDeductions,
  calculateMinimumTax,
  calculateTaxableIncome,
  calculateYearlyConsolidated,
  calculateYearlyDeductions,
  calculateYearlyMinimumTax,
  calculateYearlyTaxableIncome,
  commafy,
  taxDue,
} from "../../services/tcalc";
import { deduction } from "../../interface/news.interface";

const Estimate = ({
  complete,
  taxable,
  nonTaxable,
  action,
  isMonthly,
  timePeriod,
}: any) => {
  const [first, setFirst] = useState("0");
  const [second, setSecond] = useState("0");
  const [third, setThird] = useState("0");
  const [fourth, setFourth] = useState("0");
  const [fifth, setFifth] = useState("0");
  const [sixth, setSixth] = useState("0");
  const [firstdigit, setFirstdigit] = useState("");
  const [seconddigit, setSeconddigit] = useState("");
  const [thirddigit, setThirddigit] = useState("");
  const [fourthdigit, setFourthdigit] = useState("");
  const [fifthdigit, setFifthdigit] = useState("");
  const [sixthdigit, setSixthdigit] = useState("");

  //This is a monthly breakdown result of the calculation
  const monthlyBreakdown = (data: deduction) => {
    const deduct =
      parseFloat(data.grossIncome) - parseFloat(calculateDeductions(data));
    let secondbreakdown;
    let thirdbreakdown;
    let fourthbreakdown;
    let fifthbreakdown;
    let firstbreakdown;
    let firstbreakdownfigure;
    let secondbreakdownfigure;
    let thirdbreakdownfigure;
    let fourthbreakdownfigure;
    let fifthbreakdownfigure;

    if (deduct > 0 && deduct < 25000) {
      firstbreakdownfigure = 0.07 * deduct;
      setFirstdigit(commafy(Math.round((deduct + Number.EPSILON) * 100) / 100));
      setFirst(
        commafy(Math.round((firstbreakdownfigure + Number.EPSILON) * 100) / 100)
      );
    } else if (deduct >= 25000) {
      firstbreakdownfigure = 0.07 * 25000;
      firstbreakdown = deduct - 25000;
      setFirstdigit(commafy(Math.round((25000 + Number.EPSILON) * 100) / 100));
      setFirst(
        commafy(Math.round((firstbreakdownfigure + Number.EPSILON) * 100) / 100)
      );

      if (firstbreakdown > 0 && firstbreakdown < 25000) {
        const secondbreakdownfigure = 0.11 * firstbreakdown;
        setSeconddigit(
          commafy(Math.round((firstbreakdown + Number.EPSILON) * 100) / 100)
        );
        setSecond(
          commafy(
            Math.round((secondbreakdownfigure + Number.EPSILON) * 100) / 100
          )
        );
      } else if (firstbreakdown >= 25000) {
        secondbreakdown = firstbreakdown - 25000;
        secondbreakdownfigure = 0.11 * 25000;

        setSeconddigit(
          commafy(Math.round((25000 + Number.EPSILON) * 100) / 100)
        );
        setSecond(
          commafy(
            Math.round((secondbreakdownfigure + Number.EPSILON) * 100) / 100
          )
        );

        if (secondbreakdown > 0 && secondbreakdown < 41666.67) {
          thirdbreakdownfigure = 0.15 * secondbreakdown;
          setThirddigit(
            commafy(Math.round((secondbreakdown + Number.EPSILON) * 100) / 100)
          );
          setThird(
            commafy(
              Math.round((thirdbreakdownfigure + Number.EPSILON) * 100) / 100
            )
          );
        } else if (secondbreakdown >= 41666.67) {
          thirdbreakdown = secondbreakdown - 41666.67;
          thirdbreakdownfigure = 0.15 * 41666.67;
          setThirddigit(
            commafy(Math.round((41666.67 + Number.EPSILON) * 100) / 100)
          );
          setThird(
            commafy(
              Math.round((thirdbreakdownfigure + Number.EPSILON) * 100) / 100
            )
          );

          if (thirdbreakdown > 0 && thirdbreakdown < 41666.67) {
            fourthbreakdownfigure = 0.19 * thirdbreakdown;
            setFourthdigit(
              commafy(Math.round((thirdbreakdown + Number.EPSILON) * 100) / 100)
            );
            setFourth(
              commafy(
                Math.round((fourthbreakdownfigure + Number.EPSILON) * 100) / 100
              )
            );
          } else if (thirdbreakdown >= 41666.67) {
            fourthbreakdown = thirdbreakdown - 41666.67;
            fourthbreakdownfigure = 0.19 * 41666.67;
            setFourthdigit(
              commafy(Math.round((41666.67 + Number.EPSILON) * 100) / 100)
            );
            setFourth(
              commafy(
                Math.round((fourthbreakdownfigure + Number.EPSILON) * 100) / 100
              )
            );

            if (fourthbreakdown > 0 && fourthbreakdown < 133333.33) {
              fifthbreakdownfigure = 0.21 * fourthbreakdown;
              setFifthdigit(
                commafy(
                  Math.round((fourthbreakdown + Number.EPSILON) * 100) / 100
                )
              );
              setFifth(
                commafy(
                  Math.round((fifthbreakdownfigure + Number.EPSILON) * 100) /
                    100
                )
              );
            } else if (fourthbreakdown >= 133333.33) {
              fifthbreakdown = fourthbreakdown - 133333.33;
              fifthbreakdownfigure = 0.21 * 133333.33;

              setFifthdigit(
                commafy(Math.round((133333.33 + Number.EPSILON) * 100) / 100)
              );
              setFifth(
                commafy(
                  Math.round((fifthbreakdownfigure + Number.EPSILON) * 100) /
                    100
                )
              );

              if (fifthbreakdown > 0) {
                const sixthbreakdownfigure = 0.24 * fifthbreakdown;

                setSixthdigit(
                  commafy(
                    Math.round((fifthbreakdown + Number.EPSILON) * 100) / 100
                  )
                );
                setSixth(
                  commafy(
                    Math.round((sixthbreakdownfigure + Number.EPSILON) * 100) /
                      100
                  )
                );
              }
            }
          }
        }
      }
    }
  };

  //This is a yearly breakdown result of the calculation
  const yearlyBreakdown = (data: deduction) => {
    const deduct =
      parseFloat(data.grossIncome) -
      parseFloat(calculateYearlyDeductions(data));
    let secondbreakdown;
    let thirdbreakdown;
    let fourthbreakdown;
    let fifthbreakdown;
    let firstbreakdown;
    let firstbreakdownfigure;
    let secondbreakdownfigure;
    let thirdbreakdownfigure;
    let fourthbreakdownfigure;
    let fifthbreakdownfigure;

    if (deduct > 0 && deduct < 300000) {
      firstbreakdownfigure = 0.07 * deduct;
      setFirstdigit(commafy(Math.round((deduct + Number.EPSILON) * 100) / 100));
      setFirst(
        commafy(Math.round((firstbreakdownfigure + Number.EPSILON) * 100) / 100)
      );
    } else if (deduct >= 300000) {
      firstbreakdownfigure = 0.07 * 300000;
      firstbreakdown = deduct - 300000;
      setFirstdigit(commafy(Math.round((300000 + Number.EPSILON) * 100) / 100));
      setFirst(
        commafy(Math.round((firstbreakdownfigure + Number.EPSILON) * 100) / 100)
      );

      if (firstbreakdown > 0 && firstbreakdown < 300000) {
        const secondbreakdownfigure = 0.11 * firstbreakdown;
        setSeconddigit(
          commafy(Math.round((firstbreakdown + Number.EPSILON) * 100) / 100)
        );
        setSecond(
          commafy(
            Math.round((secondbreakdownfigure + Number.EPSILON) * 100) / 100
          )
        );
      } else if (firstbreakdown >= 300000) {
        secondbreakdown = firstbreakdown - 300000;
        secondbreakdownfigure = 0.11 * 300000;
        setSeconddigit(
          commafy(Math.round((300000 + Number.EPSILON) * 100) / 100)
        );
        setSecond(
          commafy(
            Math.round((secondbreakdownfigure + Number.EPSILON) * 100) / 100
          )
        );

        if (secondbreakdown > 0 && secondbreakdown < 500000) {
          thirdbreakdownfigure = 0.15 * secondbreakdown;
          setThirddigit(
            commafy(Math.round((secondbreakdown + Number.EPSILON) * 100) / 100)
          );
          setThird(
            commafy(
              Math.round((thirdbreakdownfigure + Number.EPSILON) * 100) / 100
            )
          );
        } else if (secondbreakdown >= 500000) {
          thirdbreakdown = secondbreakdown - 500000;
          thirdbreakdownfigure = 0.15 * 500000;
          setThirddigit(
            commafy(Math.round((500000 + Number.EPSILON) * 100) / 100)
          );
          setThird(
            commafy(
              Math.round((thirdbreakdownfigure + Number.EPSILON) * 100) / 100
            )
          );

          if (thirdbreakdown > 0 && thirdbreakdown < 500000) {
            fourthbreakdownfigure = 0.19 * thirdbreakdown;

            setFourthdigit(
              commafy(Math.round((thirdbreakdown + Number.EPSILON) * 100) / 100)
            );
            setFourth(
              commafy(
                Math.round((fourthbreakdownfigure + Number.EPSILON) * 100) / 100
              )
            );
          } else if (thirdbreakdown >= 500000) {
            fourthbreakdown = thirdbreakdown - 500000;
            fourthbreakdownfigure = 0.19 * 500000;

            setFourthdigit(
              commafy(Math.round((500000 + Number.EPSILON) * 100) / 100)
            );
            setFourth(
              commafy(
                Math.round((fourthbreakdownfigure + Number.EPSILON) * 100) / 100
              )
            );

            if (fourthbreakdown > 0 && fourthbreakdown < 1600000) {
              fifthbreakdownfigure = 0.21 * fourthbreakdown;
              setFifthdigit(
                commafy(
                  Math.round((fourthbreakdown + Number.EPSILON) * 100) / 100
                )
              );
              setFifth(
                commafy(
                  Math.round((fifthbreakdownfigure + Number.EPSILON) * 100) /
                    100
                )
              );
            } else if (fourthbreakdown >= 1600000) {
              fifthbreakdown = fourthbreakdown - 1600000;
              fifthbreakdownfigure = 0.21 * 1600000;
              setFifthdigit(
                commafy(Math.round((1600000 + Number.EPSILON) * 100) / 100)
              );
              setFifth(
                commafy(
                  Math.round((fifthbreakdownfigure + Number.EPSILON) * 100) /
                    100
                )
              );

              if (fifthbreakdown > 0) {
                const sixthbreakdownfigure = 0.24 * fifthbreakdown;
                setSixthdigit(
                  commafy(
                    Math.round((fifthbreakdown + Number.EPSILON) * 100) / 100
                  )
                );
                setSixth(
                  commafy(
                    Math.round((sixthbreakdownfigure + Number.EPSILON) * 100) /
                      100
                  )
                );
              }
            }
          }
        }
      }
    }
  };
  const calculateTotalTax = () => {
    const tax =
      parseFloat(first) +
      parseFloat(second) +
      parseFloat(third) +
      parseFloat(fourth) +
      parseFloat(fifth) +
      parseFloat(sixth);

    return commafy(Math.round((tax + Number.EPSILON) * 100) / 100);
  };

  const calculateYearlyTotalTax = () => {
    const tax =
      parseFloat(first) +
      parseFloat(second) +
      parseFloat(third) +
      parseFloat(fourth) +
      parseFloat(fifth) +
      parseFloat(sixth);

    // return (Number(tax).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
    return commafy(Math.round((tax + Number.EPSILON) * 100) / 100);
  };

  //This function handle the calculation of Net Income(Month)
  //Net Income is your Gross Income less Reliefs, Allowable Deductions and Personal Income Tax.
  const netIncome = (data: deduction) => {
    let income;
    const add =
      parseFloat(data.pensionDeduction) +
      parseFloat(data.nhfContribution) +
      parseFloat(data.lifeInsurance) +
      parseFloat(data.nhisContribution) +
      parseFloat(data.mortgageInterest);
    if (
      parseFloat(calculateTotalTax()) >
      parseFloat(calculateMinimumTax(data.grossIncome))
    ) {
      income =
        parseFloat(data.grossIncome) - parseFloat(calculateTotalTax()) - add;
      return commafy(Math.round((income + Number.EPSILON) * 100) / 100);
    } else {
      income =
        parseFloat(data.grossIncome) -
        parseFloat(calculateMinimumTax(data.grossIncome)) -
        add;
      return commafy(Math.round((income + Number.EPSILON) * 100) / 100);
    }
  };
  //This function handle the calculation of Net Income(Yearly)
  //Net Income is your Gross Income less Reliefs, Allowable Deductions and Personal Income Tax.
  const netYearlyIncome = (data: deduction) => {
    let income;
    const add =
      parseFloat(data.pensionDeduction) +
      parseFloat(data.nhfContribution) +
      parseFloat(data.lifeInsurance) +
      parseFloat(data.nhisContribution) +
      parseFloat(data.mortgageInterest);

    if (
      parseFloat(calculateYearlyTotalTax()) >
      parseFloat(calculateYearlyMinimumTax(data.grossIncome))
    ) {
      income =
        parseFloat(data.grossIncome) -
        parseFloat(calculateYearlyTotalTax()) -
        add;

      return commafy(Math.round((income + Number.EPSILON) * 100) / 100);
    } else {
      income =
        parseFloat(data.grossIncome) -
        parseFloat(calculateYearlyMinimumTax(data.grossIncome)) -
        add;

      return commafy(Math.round((income + Number.EPSILON) * 100) / 100);
    }
  };

  useEffect(() => {
    if (!timePeriod) return;
    if (timePeriod.toLowerCase() === "monthly") {
      monthlyBreakdown(nonTaxable);
    } else {
      yearlyBreakdown(nonTaxable);
    }
  });

  return (
    <Card>
      {action === "non_taxable" ? (
        <>
          <div className="form-head mb-2 text-center text-secondary">
            PIT ESTIMATE
          </div>
          <div className="text-center total">
            NGN <span>{commafy(nonTaxable.grossIncome)}</span>
          </div>
          <div className="estimate-div p-4 mt-5">
            <p className="text-secondary mt-3">Time Period</p>
            <p className="mt-3">{timePeriod}</p>
          </div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">Gross Salary</p>
            <p className="mt-3">NGN {commafy(nonTaxable.grossIncome)}</p>
          </div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">Non Taxable Income</p>
            <p className="mt-3">GTB</p>
          </div>
          <div className="form-head mt-4 text-secondary">Deductibles</div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">CRA</p>
            <p className="mt-3">{`NGN ${numeral(
              isMonthly
                ? calculateConsolidated(nonTaxable.grossIncome)
                : calculateYearlyConsolidated(nonTaxable.grossIncome)
            ).format("0,0.00")}`}</p>
          </div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">Pension</p>
            <p className="mt-3">NGN {commafy(nonTaxable.pensionDeduction)}</p>
          </div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">Life Insurance Premium</p>
            <p className="mt-3"> NGN {commafy(nonTaxable.lifeInsurance)}</p>
          </div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">Interest on mortgage loan</p>
            <p className="mt-3"> NGN {commafy(nonTaxable.mortgageInterest)}</p>
          </div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">NHF contribution</p>
            <p className="mt-3">NGN {commafy(nonTaxable.nhfContribution)}</p>
          </div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">NHISF contribution</p>
            <p className="mt-3">NGN {commafy(nonTaxable.nhisContribution)}</p>
          </div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">Total Taxable Income</p>
            <p className="mt-3">
              NGN
              {isMonthly
                ? calculateTaxableIncome(nonTaxable)
                : calculateYearlyTaxableIncome(nonTaxable)}
            </p>
          </div>
          <div className="form-head mt-4 text-secondary">Tax Breakdown</div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">Total Calculated Tax</p>
            <p className="mt-3">
              {" "}
              {isMonthly
                ? `NGN ${numeral(calculateTotalTax()).format("0,0.00")}`
                : `NGN ${numeral(calculateYearlyTotalTax()).format("0,0.00")}`}
            </p>
          </div>
          {first !== "0" ? (
            <>
              {" "}
              <div className="estimate-div p-4 mt-4">
                <p className="text-secondary mt-3">
                  Tax on first NGN ${firstdigit}
                </p>
                <p className="mt-3">NGN {first}</p>
              </div>
            </>
          ) : (
            <></>
          )}
          {second !== "0" ? (
            <>
              {" "}
              <div className="estimate-div p-4 mt-4">
                <p className="text-secondary mt-3">
                  Tax on second NGN ${seconddigit}
                </p>
                <p className="mt-3">NGN {second}</p>
              </div>
            </>
          ) : (
            <></>
          )}
          {third !== "0" ? (
            <>
              {" "}
              <div className="estimate-div p-4 mt-4">
                <p className="text-secondary mt-3">
                  Tax on third NGN ${thirddigit}
                </p>
                <p className="mt-3">NGN {third}</p>
              </div>
            </>
          ) : (
            <></>
          )}
          {fourth !== "0" ? (
            <>
              {" "}
              <div className="estimate-div p-4 mt-4">
                <p className="text-secondary mt-3">
                  Tax on fourth NGN ${fourthdigit}
                </p>
                <p className="mt-3">NGN {fourth}</p>
              </div>
            </>
          ) : (
            <></>
          )}
          {fifth !== "0" ? (
            <>
              {" "}
              <div className="estimate-div p-4 mt-4">
                <p className="text-secondary mt-3">
                  Tax on fifth NGN ${fifthdigit}
                </p>
                <p className="mt-3">NGN {fifth}</p>
              </div>
            </>
          ) : (
            <></>
          )}
          {sixth !== "0" ? (
            <>
              {" "}
              <div className="estimate-div p-4 mt-4">
                <p className="text-secondary mt-3">
                  Tax on sixth NGN ${sixthdigit}
                </p>
                <p className="mt-3">NGN {sixth}</p>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">Minimum Tax Consideration</p>
            <p className="mt-3">
              {`NGN ${numeral(
                isMonthly
                  ? calculateMinimumTax(nonTaxable.grossIncome)
                  : calculateYearlyMinimumTax(nonTaxable.grossIncome)
              ).format("0,0.00")}`}
            </p>
          </div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">Minimum Tax Due</p>
            <p className="mt-3">
              {`NGN ${numeral(taxDue(nonTaxable)).format("0,0.00")}`}
            </p>
          </div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">Total Tax Payable</p>
            <p className="mt-3">
              {`NGN ${numeral(
                isMonthly ? calculateTotalTax() : calculateYearlyTotalTax()
              ).format("0,0.00")}`}
            </p>
          </div>
          <div className="estimate-div p-4 mt-4">
            <p className="text-secondary mt-3">Net Income</p>
            <p className="mt-3">
              {`NGN ${numeral(
                isMonthly ? netIncome(nonTaxable) : netYearlyIncome(nonTaxable)
              ).format("0,0.00")}`}
            </p>
          </div>
          <div className="mt-4">
            <button
              className="btn btn-primary w-100 btn-pri"
              onClick={complete}
            >
              Save Result
            </button>
          </div>
        </>
      ) : (
        <>
          {" "}
          <>
            <div className="form-head mb-2 text-center text-secondary">
              PIT ESTIMATE
            </div>
            <div className="text-center total">
              NGN <span>5,100.00</span>
            </div>
            <div className="estimate-div p-4 mt-5">
              <p className="text-secondary mt-3">Time Period</p>
              <p className="mt-3">1 Month</p>
            </div>
            <div className="estimate-div p-4 mt-4">
              <p className="text-secondary mt-3">Gross Income</p>
              <p className="mt-3">NGN 500,000.00</p>
            </div>
            <div className="estimate-div p-4 mt-4">
              <p className="text-secondary mt-3">Non Taxable Income</p>
              <p className="mt-3">GTB</p>
            </div>
            <div className="form-head mt-4 text-secondary">PIT ESTIMATE</div>
            <div className="estimate-div p-4 mt-4">
              <p className="text-secondary mt-3">CRA</p>
              <p className="mt-3">N500,000.00</p>
            </div>
            <div className="estimate-div p-4 mt-4">
              <p className="text-secondary mt-3">Pension</p>
              <p className="mt-3">N100.00</p>
            </div>
            <div className="estimate-div p-4 mt-4">
              <p className="text-secondary mt-3">Life Insurance Premium</p>
              <p className="mt-3">N100.00</p>
            </div>
            <div className="estimate-div p-4 mt-4">
              <p className="text-secondary mt-3">Interest on mortgage loan</p>
              <p className="mt-3">N100.00</p>
            </div>
            <div className="mt-4">
              <button
                className="btn btn-primary w-100 btn-pri"
                onClick={complete}
              >
                Save Result
              </button>
            </div>
          </>
        </>
      )}
    </Card>
  );
};

export default Estimate;
