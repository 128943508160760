import { Form as AppForm } from "react-bootstrap";
import "./form.css";
interface formProps {
  onChange: (e: any) => void;
  type: string;
  placeholder: string;
  options: Array<any>;
}
const Select = ({
  onChange,
  type,
  placeholder,
  options,
  ...restProps
}: formProps) => {
  return (
    <AppForm.Select
      className="form-input shadow-none mb-4"
      onChange={onChange}
      placeholder={placeholder}
      {...restProps}
    >
      {options.length &&
        options.map((i, idx) => <option key={idx}>{i}</option>)}
    </AppForm.Select>
  );
};

export default Select;
