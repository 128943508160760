import Card from "../../Components/cards/form-cards";
import Form from "../../Components/forms/form";
import Select from "../../Components/forms/select";

const NoneTaxable = ({ complete, onChange, values }: any) => {
  return (
    <Card>
      <div className="form-head mb-2">Non-Taxable Income</div>

      <div className="form">
        <label>Time Period</label>
        <Select
          onChange={(e) => onChange("time_period", e.target.value)}
          type="text"
          options={["Monthly", "Yearly"]}
          placeholder="Monthly"
        />
      </div>

      <div className="form mt-4">
        <label>Gross Salary</label>
        <Form
          onChange={(e) => onChange("salary", e.target.value)}
          type="text"
          placeholder="E.g. 20,000.00"
          showNaira={false}
          value={values.grossIncome}
          showQuestion={true}
          id={""}
          ref={undefined} required={false}        />
      </div>

      <div className="form">
        <label>Pension Deduction</label>
        <Form
          onChange={(e) => onChange("pension_deduction", e.target.value)}
          type="text"
          placeholder="E.g. 20,000.00"
          showNaira={false}
          showQuestion={true}
          id={""}
          ref={undefined}
          value={values.pensionDeduction} required={false}        />
      </div>

      <div className="form">
        <label>NHF contribution</label>
        <Form
          onChange={(e) => onChange("nhf_contribution", e.target.value)}
          type="text"
          placeholder="E.g. 20,000.00"
          showNaira={false}
          showQuestion={true}
          id={""}
          ref={undefined}
          value={values.nhfContribution} required={false}        />
      </div>
      <div className="form">
        <label>Life Insurance Premium</label>
        <Form
          onChange={(e) => onChange("life_insurance_premium", e.target.value)}
          type="text"
          placeholder="E.g. 20,000.00"
          showNaira={false}
          showQuestion={true}
          id={""}
          ref={undefined}
          value={values.lifeInsurance} required={false}        />
      </div>
      <div className="form">
        <label>NHIS contribution</label>
        <Form
          onChange={(e) => onChange("nhis_contribution", e.target.value)}
          type="text"
          placeholder="E.g. 20,000.00"
          showNaira={false}
          showQuestion={true}
          id={""}
          ref={undefined}
          value={values.nhisContribution} required={false}        />
      </div>
      <div className="form">
        <label>Interest on mortgage loan</label>
        <Form
          onChange={(e) => onChange("interest_on_mortgage_loan", e.target.value)}
          type="text"
          placeholder="E.g. 20,000.00"
          showNaira={false}
          showQuestion={true}
          id={""}
          ref={undefined}
          value={values.mortgageInterest} required={false}        />
      </div>
      <div className="d-flex justify-content-between mt-4">
        <button className="btn btn-secondary w-50 btn-pri">Back</button>
        <button className="btn btn-primary w-50 btn-pri" onClick={complete}>
          Next
        </button>
      </div>
    </Card>
  );
};

export default NoneTaxable;
