import "./tnews.css";
import Input from "../../Components/forms/form";
import Page from "../../Components/pages/page";
import { useEffect, useState, useRef } from "react";
import { uploadNews } from "../../services/news";
import { uploadNewsPayload } from "../../interface/news.interface";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { isLoggedIn } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const UploadNews = () => {
  // const [selectImg, setSelectedImg] = useState<any>(undefined || null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const firstInputRef = useRef<HTMLInputElement>(null);
  const secondInputRef = useRef<HTMLInputElement>(null);

  const [details, setDetails] = useState<uploadNewsPayload>({
    title: "",
    body: "",
    image_url: "",
    video_url: "",
  });
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const [news, setNews] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    const dirtyHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const cleanHTML = DOMPurify.sanitize(dirtyHTML, {
      USE_PROFILES: { html: true },
    });
    setNews(cleanHTML);
    setDetails((prevDetails) => ({
      ...prevDetails,
      body: cleanHTML,
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [id]: value,
    }));
  };

  const createNews = async () => {
    setLoading(true);
    console.log(details);
    const uploadNewsData = await uploadNews(details);
    setLoading(false);

    setDetails((prevDetails) => ({
      ...prevDetails,
      body: "",
      title: "",
      image_url: "",
    }));
    setEditorState(EditorState.createEmpty());
    return uploadNewsData;
  };
  const checkStatus = async () => {
    const status = await isLoggedIn();
    if (!status) navigate("/login");
  };
  useEffect(() => {
    checkStatus();
  });
  return (
    <Page>
      <div className="upload-news">
        <ToastContainer />
        {loading && (
          <>
            <Backdrop className={classes.backdrop} open>
              <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer />
          </>
        )}
        <h3 className="mt-4 mb-5">Upload News</h3>
        <label>Title</label>
        <Input
          ref={firstInputRef}
          showNaira={false}
          showQuestion={false}
          placeholder="Select Title"
          type="text"
          onChange={(e: any) => handleInputChange(e)}
          id={"title"}
          value={details.title}
          required={false}
        />
        <label>Image</label>
        <Input
          ref={secondInputRef}
          showNaira={false}
          showQuestion={false}
          placeholder="Image address"
          type="text"
          onChange={(e: any) => handleInputChange(e)}
          id={"image_url"}
          value={details.image_url}
          required={false}
        />

        <label className="mt-3">Type</label>
        <select className="img-input form-control mb-3 shadow-none">
          <option>News</option>
          <option>Trending</option>
          <option>Article</option>
        </select>

        <Editor
          placeholder="Type in news....."
          wrapperClassName="wrapper-class border "
          editorClassName="editor-class m-2"
          toolbarClassName="toolbar-class text-dark"
          editorState={editorState}
          toolbar={{
            options: ["inline", "fontSize", "fontFamily", "textAlign", "list"],
            inline: {
              options: ["italic", "bold"],
            },
            blockType: {
              className: "demo-option-custom-wide",
              dropdownClassName: "demo-dropdown-custom",
            },
            fontSize: { className: "demo-option-custom-medium" },
          }}
          onEditorStateChange={(editorState) => {
            onEditorStateChange(editorState);
          }}
        />
        <button
          className="btn btn-primary mt-4"
          onClick={() => {
            createNews();
          }}
        >
          Upload
        </button>
      </div>
    </Page>
  );
};

export default UploadNews;
