import { useState } from "react";
import Card from "../../Components/cards/form-cards";
import Form from "../../Components/forms/form";
import Select from "../../Components/forms/select";

const Taxable = ({ complete, onChange }: any) => {
  const [displayAll, setDisplayAll] = useState(false);

  return (
    <Card>
      <div className="form">
        <label>Time Period</label>
        <Select
          onChange={(e) => onChange("time_period", e.target.value)}
          type="text"
          options={["Daily", "Monthly", "Yearly"]}
          placeholder="Monthly"
        />
      </div>
      <div className="form-head">Taxable Income</div>
      <div className="form">
        <label>Salary</label>
        <Form
          onChange={(e) => onChange("salary", e.target.value)}
          type="number"
          placeholder="E.g. 20,000.00"
          showNaira={true}
          showQuestion={true}
          id={""}
          ref={undefined} value={""} required={false}        />
      </div>

      <div className="form">
        <label>Commission</label>
        <Form
          onChange={(e) => onChange("commission", e.target.value)}
          type="text"
          placeholder="E.g. 20,000.00"
          showNaira={true}
          showQuestion={true}
          id={""}
          ref={undefined} value={""} required={false}        />
      </div>

      <div className="form">
        <label>Trade Income</label>
        <Form
          onChange={(e) => onChange("trade_income", e.target.value)}
          type="text"
          placeholder="E.g. 20,000.00"
          showNaira={true}
          showQuestion={true}
          id={""}
          ref={undefined} value={""} required={false}        />
      </div>
      <div className="form">
        <label>Pension Received</label>
        <Form
          onChange={(e) => onChange("pension_received", e.target.value)}
          type="text"
          placeholder="E.g. 20,000.00"
          showNaira={true}
          showQuestion={true}
          id={""}
          ref={undefined} value={""} required={false}        />
      </div>
      <div className="form">
        <label>Annuity</label>
        <Form
          onChange={(e) => onChange("annuity", e.target.value)}
          type="text"
          placeholder="E.g. 20,000.00"
          showNaira={true}
          showQuestion={true}
          id={""}
          ref={undefined} value={""} required={false}        />
      </div>
      <div
        onClick={() => setDisplayAll(!displayAll)}
        className="d-flex justify-content-between align-items-center see-more p-3 mt-3"
      >
        {displayAll ? (
          <p className="mt-3">Less</p>
        ) : (
          <p className="mt-3">More</p>
        )}
        {displayAll ? (
          <img alt="img" className="more" src="/assets/icons/arrow-up.svg" />
        ) : (
          <img alt="img" className="more" src="/assets/icons/arrow-down.svg" />
        )}
      </div>
      {!displayAll && (
        <button className="btn btn-primary mt-3 btn-pri" onClick={complete}>
          Next
        </button>
      )}
      {displayAll && (
        <div className="mt-3 more">
          <div className="form">
            <label>Gratuities</label>
            <Form
              onChange={(e) => onChange("gratuities", e.target.value)}
              type="number"
              placeholder="E.g. 20,000.00"
              showNaira={true}
              showQuestion={true}
              id={""}
              ref={undefined} value={""} required={false}            />
          </div>

          <div className="form">
            <label>Foreign Income</label>
            <Form
              onChange={(e) => onChange("foreign_income", e.target.value)}
              type="text"
              placeholder="E.g. 20,000.00"
              showNaira={true}
              showQuestion={true}
              id={""}
              ref={undefined} value={""} required={false}            />
          </div>

          <div className="form">
            <label>Dividend</label>
            <Form
              onChange={(e) => onChange("dividend", e.target.value)}
              type="text"
              placeholder="E.g. 20,000.00"
              showNaira={true}
              showQuestion={true}
              id={""}
              ref={undefined} value={""} required={false}            />
          </div>
          <div className="form">
            <label>Interest</label>
            <Form
              onChange={(e) => onChange("interest", e.target.value)}
              type="number"
              placeholder="E.g. 20,000.00"
              showNaira={true}
              showQuestion={true}
              id={""}
              ref={undefined} value={""} required={false}            />
          </div>

          <div className="form">
            <label>Rent</label>
            <Form
              onChange={(e) => onChange("rent", e.target.value)}
              type="text"
              placeholder="E.g. 20,000.00"
              showNaira={true}
              showQuestion={true}
              id={""}
              ref={undefined} value={""} required={false}            />
          </div>

          <div className="form">
            <label>Others</label>
            <Form
              onChange={(e) => onChange("others", e.target.value)}
              type="text"
              placeholder="E.g. 20,000.00"
              showNaira={true}
              showQuestion={true}
              id={""}
              ref={undefined} value={""} required={false}            />
          </div>

          <div className="d-flex justify-content-between mt-4">
            <button className="btn btn-secondary w-50 btn-pri">Back</button>
            <button className="btn btn-primary w-50 btn-pri" onClick={complete}>
              Next
            </button>
          </div>
        </div>
      )}
    </Card>
  );
};

export default Taxable;
