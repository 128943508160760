import { PropsWithChildren, FC } from "react";
import Navbar from "../layouts/navbar";
import Footer from "../layouts/footer";

interface PageProps extends React.PropsWithChildren {
  children?: React.ReactNode;
}

const Page: FC<PropsWithChildren<PageProps>> = ({ children, ...restProps }) => {
  return (
    <div {...restProps}>
      <Navbar />
      <div className='app'></div>
      {children}
      <Footer />
    </div>
  );
};

export default Page;
