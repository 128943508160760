import Page from "../../Components/pages/page";
import Card from "../../Components/cards/card";
import { dummyNewsInterface, NewsType } from "../../interface/news.interface";
import { article, trending } from "../../datas";
import "./tnews.css";
import { allNews, deleteNews, updatePost } from "../../services/news";
import "../../Components/cards/card.css";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { isLoggedIn } from "../../services/auth";
import SendFundz from "./send-funds";
import { ToastContainer } from "react-toastify";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";

const AllNews = () => {
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState([]);
  const [status, setStatus] = useState(false);
  const fetchData = async () => {
    const response: any = await allNews();
    const sortedCourses: any = [...response.data].sort((a, b) => {
      const dateA: any = new Date(a.createdAt ? a.createdAt : a.created_on);
      const dateB: any = new Date(b.createdAt ? b.createdAt : b.created_on);
      return dateB - dateA;
    });
    setNewsData(sortedCourses);
  };
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [editModal, setEditModal] = useState<any>({});
  const [activeNews, setActiveNews] = useState("Trending");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const changeNewsType = (type: string, data: any) => {
    setActiveNews(type);
    setData(data);
  };
  const checkStatus = async () => {
    const status = await isLoggedIn();
    if (status) setStatus(true);
  };
  const deletePost = async (id: any) => {
    return await deleteNews(id);
  };
  useEffect(() => {
    fetchData();
    setData(trending);
    checkStatus();
  }, [newsData]);

  const handleEditModal = (editData: object | any) => {
    if (editData && editData.text) {
      const blocksFromHTML = convertFromHTML(editData.text);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(content));
    }
    setEditModal(editData);
  };

  const toggleModal = () => {
    if (editModal._id) {
      setEditModal({});
      setEditorState(EditorState.createEmpty());
    }
  };

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    const dirtyHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const cleanHTML = DOMPurify.sanitize(dirtyHTML, {
      USE_PROFILES: { html: true },
    });
    onChange("text", cleanHTML);
  };

  const onChange = (type: string, data: string) => {
    if (type === "title") {
      setEditModal({ ...editModal, title: data });
    } else if (type === "text") {
      setEditModal({ ...editModal, body: data });
    } else if (type === "image") {
      setEditModal({ ...editModal, image_url: data });
    } else if (type === "type") {
      setEditModal({ ...editModal, type: data });
    }
  };

  const updateNews = async () => {
    setLoading(true);
    const update = await updatePost(editModal._id, editModal);
    await toggleModal();
    setLoading(false);
    return update;
  };

  return (
    <Page>
      <div className="tnews">
        <ToastContainer />
        {loading && (
          <>
            <Backdrop className={classes.backdrop} open>
              <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer />
          </>
        )}
        <div className="tab">
          <div className="m-auto inner">
            <div className="d-flex align-items-center">
              <img
                alt="img"
                src={
                  activeNews === "Trending"
                    ? "/assets/icons/active-trend.svg"
                    : "/assets/icons/trend.svg"
                }
              />
              <p
                className={activeNews === "Trending" ? "active mt-3" : "mt-3"}
                onClick={() => changeNewsType("Trending", trending)}
              >
                Trending
              </p>
            </div>
            <p
              className={activeNews === "News" ? "active mt-sm" : "mt-3"}
              onClick={() => changeNewsType("News", newsData)}
            >
              News
            </p>
            <p
              className={activeNews === "Articles" ? "active" : "mt-3"}
              onClick={() => changeNewsType("Articles", article)}
            >
              Articles
            </p>
            <p className="follow pt-3 sm-none">Follow Us</p>+{" "}
            <div className="d-flex w-100 justify-content-between sm-none">
              <img alt="img" src="/assets/icons/instagram-icon.svg" />
              <img alt="img" src="/assets/icons/facebook-icon.svg" />
              <img alt="img" src="/assets/icons/twitter-icon.svg" />
            </div>
          </div>
        </div>
        <div className="row news-list">
          <div className="mt-5 mb-3">
            <h2 className="news-title ">{activeNews}</h2>
          </div>

          {newsData?.length ? (
            newsData.map(
              (
                {
                  title,
                  content,
                  image_url,
                  _id,
                  seen_by,
                  loved_by,
                  deleted_on,
                  is_deleted,
                  active,
                  created_by,
                  slug,
                  created_no,
                  created_on,
                  createdAt,
                  __v,
                }: NewsType,
                index
              ) => {
                return (
                  <div
                    className={
                      index === 0 ? "col-12 d-flex" : "col-lg-6 col-sm-12"
                    }
                  >
                    <Card
                      first={index === 0 ? true : false}
                      key={title}
                      text={content}
                      title={title}
                      date={createdAt ? createdAt : created_on}
                      image={image_url}
                      editModal={editModal}
                      handleEditModal={handleEditModal}
                      toggleModal={toggleModal}
                      onChange={onChange}
                      updateNews={updateNews}
                      _id={_id}
                      type={"News"}
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                      deleteNews={() => {
                        deletePost(_id);
                      }}
                    />
                  </div>
                );
              }
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="send-funds">
        <div className="spaces"></div>
        <SendFundz />
      </div>
    </Page>
  );
};

export default AllNews;
