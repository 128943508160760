import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <img
          alt="img"
          src="/assets/images/tbook.png"
          width="47.78"
          height="45"
          className="border-10 "
        />
        <div className="d-flex w-25 justify-content-between mt-5 icons">
          <img alt="img" src="/assets/icons/instagram-icon.svg" />
          <img alt="img" src="/assets/icons/facebook-icon.svg" />
          <img alt="img" src="/assets/icons/twitter-icon.svg" />
        </div>
        <p className="text-secondary mt-2 footer-top">
          2015-2023 &copy; Taxaide Technologies Limited. All rights reserved
        </p>
      </div>
      <div className="d-flexfooter-list justify-content-between">
        <div>
          <h4>About Us</h4>
          <p className="text-secondary">
            <a className="footer-link" href="https://www.taxitpay.com.ng/about">
              About
            </a>
          </p>
          <p className="text-secondary">
            <a
              className="footer-link"
              href="https://www.taxitpay.com.ng/privacy"
            >
              Legal & privacy
            </a>
          </p>
        </div>
        <div>
          <h4>Products</h4>
          <p className="text-secondary">
            <a className="footer-link" href="https://www.taxitpay.com.ng">
              TBook®
            </a>
          </p>
          <p className="text-secondary">
            <a className="footer-link" href="https://www.taxitpay.com.ng">
              TaxiTWithhold®{" "}
            </a>
          </p>
          <p className="text-secondary">
            <a className="footer-link" href="https://www.taxitpay.com.ng">
              TaxiTPayroll®{" "}
            </a>
          </p>
        </div>
        <div>
          <a href="https://apps.apple.com/ng/app/tbook/id1535069197">
            <img src="/assets/images/apple.png" width="100" height="70" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=ng.com.taxtech.tbook&hl=en&gl=US">
            <img src="/assets/images/google.png" width="100" height="70" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
