import { Form as AppForm, InputGroup } from "react-bootstrap";
import { formProps } from "../../interface/news.interface";
import "./form.css";

const Form = ({
  onChange,
  type,
  placeholder,
  showNaira,
  required,
  showQuestion,
  ...restProps
}: formProps) => {
  const className = (question: boolean, naira: boolean, type: string) => {
    if (question && naira) {
      return "form-input shadow-none bdr-right bdr-left";
    } else if (!question && naira) {
      return "form-input shadow-none bdr-left";
    } else if (question && !naira) {
      return "form-input shadow-none bdr-right";
    } else if (type === "password") {
      return "shadow-none bdr-right";
    } else {
      return "shadow-none";
    }
  };
  return (
    <InputGroup
      className={!showNaira && !showQuestion ? "h-48 mb-3" : "box-shadow mb-3"}
    >
      {showNaira && (
        <InputGroup.Text
          className="form-input bg-transparent bdr-right"
          id="basic-addon1"
        >
          ₦
        </InputGroup.Text>
      )}
      <AppForm.Control
        className={className(showQuestion, showNaira, type)}
        onChange={onChange}
        type={type || "string"}
        placeholder={placeholder}
        required
        {...restProps}
      />
      {showQuestion && (
        <InputGroup.Text
          className="form-input border-left-0 bg-white bdr-left"
          id="basic-addon1 border-left-0 form-input "
        >
          <img
            alt="img"
            src={
              type === "password"
                ? "/assets/icons/eye-off.svg"
                : "/assets/icons/Union.svg"
            }
          />
        </InputGroup.Text>
      )}

      {type === "password" && (
        <InputGroup.Text
          className="border-left-0 bg-white bdr-left"
          id="basic-addon1 border-left-0 form-input "
        >
          <img alt="img" src="/assets/icons/eye-off.svg" />
        </InputGroup.Text>
      )}
    </InputGroup>
  );
};

export default Form;
