import { PropsWithChildren, FC } from "react";
import "./card.css";

interface PageProps extends React.PropsWithChildren {
  children?: React.ReactNode;
}
const Card: FC<PropsWithChildren<PageProps>> = ({ children, ...restProps }) => {
  return (
    <div className='border-0 form-card p-5 card-top-form' {...restProps}>
      {children}
    </div>
  );
};

export default Card;
