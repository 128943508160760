import CryptoJS from "crypto-js";
export const PRIVATE_KEY = process.env.REACT_APP_BACKEND_PRIVATE_KEY
export const PUBLIC_KEY = process.env.REACT_APP_BACKEND_PUBLIC_KEY

export function encryptReqBody(
   data: any,
   files?: any
): { encrypted: string; files?: any } | undefined {
   try {
      const val = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
      //@ts-ignore
      const encrypted = CryptoJS.AES.encrypt(val, PRIVATE_KEY, { iv: PUBLIC_KEY }).toString();
      const b64 = CryptoJS.enc.Base64.parse(encrypted).toString(CryptoJS.enc.Hex);

      // console.log(files);
      return { encrypted: b64, files };
   } catch (e) {
      console.log(e);
      return undefined;
   }
}