import Page from "../../Components/pages/page";
import "./tquiz.css";
import { Modal } from "react-bootstrap";
import ClassicMode from "./classic-mode";
import OracleMode from "./oracle-mode";
import { Link, useNavigate } from "react-router-dom";
import TimedMode from "./timed-mode";
import { useEffect, useState } from "react";
import { isLoggedIn } from "../../services/auth";
import { getQuiz } from "../../services/tquiz";

function QuizModal(props: any) {
  const navigate = useNavigate();

  //check if the user is logged in
  const checkStatus = async () => {
    const status = await isLoggedIn();
    if (!status) navigate("/login");
  };
  useEffect(() => {
    checkStatus();
  });
  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <div className="m-2">
          <div className="question-indicator mb-2">
            <img alt="star-img" src="/assets/icons/star-icon.svg" />
            <p>15</p>
          </div>
          <div className="question-indicator-btm mb-5"></div>
          <p>Are you confident in your tax knowldge? </p>
          <p>Answer 15 questions. </p>
          <p className="time-indicator">No time limit!</p>
          <div className="d-flex justify-content-between mt-4">
            <button
              className="btn btn-secondary w-50 btn-pri"
              onClick={props.cancel}
            >
              Close
            </button>
            <button
              className="btn btn-primary w-50 btn-pri"
              onClick={() => {
                props.setStart();
                props.onHide();
              }}
            >
              Start Quiz
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const Tquiz = () => {
  const [mode, set_mode] = useState("");
  const [modal, setModal] = useState(false);
  const [start, setStart] = useState(false);
  const [restQuestions, setRestQuestions] = useState<any>([]);
  const [status, setStatus] = useState(false);

  const switchMode = async (text: any) => {
    await set_mode(text);
    await setModal(true);
    getQuestions();
  };
  const checkStatus = async () => {
    const status = await isLoggedIn();
    if (!status) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  };

  const cancel = async () => {
    await set_mode("");
    await setModal(false);
  };

  const changeStart = () => {
    setStart(!start);
  };

  const backHome = () => {
    set_mode("");
  };
  const getQuestions = async () => {
    const response = await getQuiz();
    setRestQuestions(response.data);
  };
  useEffect(() => {
    checkStatus();
  });
  return (
    <Page>
      <div className="quiz-list">
        {!mode && (
          <>
            <div className="large-row ">
              <h2 className="quiz-title mt-2 float-left">TQuiz</h2>
              {status && (
                <Link
                  className="text-decoration-none float-right mt-3"
                  to={"/upload-quiz"}
                >
                  <button className="btn-nav">Create Quiz</button>
                </Link>
              )}
            </div>

            <div className="quiz-display mt-5">
              <h3>
                How much do you know about taxation in Nigeria? Test your
                knowledge with TQuiz!
              </h3>
              <img alt="img" src="/assets/images/quiz-display-img.svg" />
            </div>
            <div className="quiz-types">
              <div onClick={() => switchMode("classic")}>
                <img alt="img" src="/assets/icons/classic-mode.svg" />
                <p>Classic Mode</p>
              </div>
              <div onClick={() => switchMode("oracle")}>
                <img alt="img" src="/assets/icons/oracle-mode.svg" />
                <p>Oracle Mode</p>
              </div>
              <div onClick={() => switchMode("timed")}>
                <img alt="img" src="/assets/icons/timed-mode.svg" />
                <p>Timed Mode</p>
              </div>
            </div>
          </>
        )}
        {mode === "classic" && (
          <ClassicMode
            start={start}
            setStart={changeStart}
            backHome={backHome}
            data={restQuestions}
          />
        )}
        {mode === "oracle" && (
          <OracleMode
            start={start}
            setStart={changeStart}
            data={restQuestions}
            backHome={backHome}
          />
        )}
        {mode === "timed" && (
          <TimedMode
            start={start}
            setStart={changeStart}
            backHome={backHome}
            data={restQuestions}
          />
        )}
      </div>

      <QuizModal
        show={modal}
        mode={mode}
        cancel={cancel}
        set_mode={set_mode}
        setStart={changeStart}
        onHide={() => setModal(false)}
      />
    </Page>
  );
};

export default Tquiz;
