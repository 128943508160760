import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { toast, ToastContainer } from "react-toastify";
import { verifyEmail } from "../../services/auth";

export const Otp = ({ email, ...restProps }: any) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  const confirmEmail = async () => {
    setLoading(true);
    const mailToLowerCase = email.toLowerCase();
    const response = await verifyEmail(mailToLowerCase, otp);
    setLoading(false);
    if (response === "success") navigate("/tnews");
    return response;
  };

  return (
    <div className='login'>
      {loading && (
        <>
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color='inherit' />
          </Backdrop>
        </>
      )}
      <ToastContainer />
      <img
        alt='img'
        src='/assets/images/tbook.png'
        width='47.78'
        height='45'
        className='border-10 '
      />
      <h3 className='mt-4'>Verify Email</h3>
      <p className='mb-3 text-secondary'>An Otp has been sent to your mail.</p>
      <div className='d-flex justify-content-between mt-4'>
        <div className='d-flex w-100'>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>-</span>}
            renderInput={(props: any) => <input {...props} />}
            inputStyle={{
              width: "100%",
              height: "50px",
              margin: "0 10px",
              fontSize: "20px",
              borderRadius: "5px",
              textAlign: "center",
              border: "1px solid #ccc",
            }}
          />
        </div>
      </div>{" "}
      <button
        className='btn btn-primary mt-4'
        onClick={() => {
          confirmEmail();
        }}
      >
        Verify
      </button>
    </div>
  );
};
