import "./tquiz.css";
import Card from "../../Components/cards/form-cards";
import { ProgressBar } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { questions } from "../../datas";
import { postQuizAnswer } from "../../services/tquiz";

function Correct(props: any) {
  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <div className="m-2">
          <div className="mb-2">
            <img alt="star-img" src="/assets/icons/correct-icon.svg" />
          </div>
          <p className="bold">Correct!</p>
          <p>
            {props?.answer} is the correct answer for {props?.question}
          </p>
          <button onClick={props.nextQuestion} className="btn btn-primary mt-4">
            Continue
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function InCorrect(props: any) {
  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <div className="m-2">
          <div className="mb-2">
            <img alt="star-img" src="/assets/icons/correct-icon.svg" />
          </div>
          <p className="bold">Not Correct!</p>
          <p>
            {props?.answer} is the correct answer for {props?.question}
          </p>
          <button onClick={props.nextQuestion} className="btn btn-primary mt-4">
            Continue
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Result(props: any) {
  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <div className="m-2">
          <div className="mb-2">
            <img
              alt="star-img"
              width="100%"
              src="/assets/images/result-img.svg"
            />
          </div>
          <div className="scores-card">
            <div className="scores">
              <div className="title-1">
                {" "}
                <p>Your Score</p>
              </div>{" "}
              <p className="time-text">{props.result}%</p>
            </div>
            <div className="scores">
              <div className="title-2">
                <p>Your Time</p>
              </div>{" "}
              <p className="time-text">
                {props.minute}:{props.seconds}
              </p>
            </div>
            <div className="scores">
              <div className="title-3">
                <p>Top Score</p>
              </div>
              <p className="time-text">100%</p>
            </div>
          </div>
          <p className="performance-text mt-4">
            You performed better than 19% of users.
          </p>

          <div className="d-flex justify-content-between mt-4">
            <button
              className="btn btn-secondary w-50 btn-pri"
              onClick={props.goHome}
            >
              Home
            </button>
            <button
              className="btn btn-primary w-50 btn-pri"
              onClick={props.shareResult}
            >
              Share Results
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const TimedMode = ({ start, setStart, backHome, data }: any) => {
  const [selected, setSelected] = useState<any>({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showCorrectModal, setShowCorrectModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [minutes, setMinutes] = useState(15);
  let [seconds, setSeconds] = useState(0);
  const [answerList, setAnswerList] = useState<any>([]);
  const [showInCorrectModal, setShowInCorrectModal] = useState(false);
  const [showResult, setShowResult] = useState(false);
  let [correct, setCorrect] = useState(0);
  const countDownDate = new Date(new Date().setMinutes(0, 0)).getTime();
  const [countDown, setCountDown] = useState<any>(countDownDate);
  const [answer, setAnswer] = useState();
  useEffect(() => {
    if (!start) return;
    questionCheck();
    const interval = setInterval(() => {
      if (seconds === 0) {
        setSeconds(59);
        setMinutes(minutes - 1);
      } else {
        setSeconds(seconds--);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [seconds, start]);

  const submit = async () => {
    const payload = {
      type: "timed",
      time: `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`,
      score: ((correct / data?.length) * 100).toFixed(2),
    };
    await postQuizAnswer(payload);
  };
  const questionCheck = () => {
    if (minutes === 0 && seconds === 0) {
      setStart();
      submit();
    }
  };

  const shareResult = async () => {
    await alert("Shared");
    await setShowResult(false);
    await backHome();
  };

  const goHome = () => {
    setShowResult(false);
    backHome();
  };

  const setAnswers = async () => {
    if (!selected.answer) return alert("Please select an answer");
    /*toast.warn("Select an option", {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        transition: Slide,
        hideProgressBar: true,
      });*/

    await setAnswerList([
      ...answerList,
      {
        question: data[currentQuestion]?.question,
        selectedAnswer: selected?.answer,
      },
    ]);
    const currentQuestionData = data[currentQuestion]; // Access the current question data

    // Extract the question, answer, and options from currentQuestionData
    const { question, answer, option_one, option_two, option_three } =
      currentQuestionData;

    // Map the answer property to the correct option value
    const options: any = {
      option_one,
      option_two,
      option_three,
    };
    setAnswer(options[answer]);
    if (selected?.answer === data[currentQuestion]?.answer) {
      await setCorrect(correct + 1);
      await setShowCorrectModal(true);
    } else {
      await setShowInCorrectModal(true);
    }
    await setSelected({});
  };

  const nextQuestion = () => {
    if (currentQuestion + 1 >= data?.length) {
      setStart();
      setShowCorrectModal(false);
      setShowInCorrectModal(false);
      setProgress(100);
      submit();
      setShowResult(true);
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setShowCorrectModal(false);
      setShowInCorrectModal(false);
      setProgress(((currentQuestion + 1) / data?.length) * 100);
    }
  };

  return (
    <div className="">
      <h2 className="quiz-title mt-2">Timed Quiz</h2>
      <div className="mt-4 quiz-container">
        <Card>
          {data.length ? (
            <>
              <ProgressBar
                now={progress}
                className="mb-5 progress-bars"
                variant="#D88C9A"
              />

              <div className="d-flex justify-content-between ">
                <h3>Question {currentQuestion + 1}</h3>{" "}
                <h2>
                  {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </h2>
              </div>
              <p>{data[currentQuestion]?.question}</p>
              <div className="row">
                <div
                  className={
                    selected.answer === "option_one"
                      ? "col-6 option option-selected"
                      : "col-6 option"
                  }
                  onClick={() => {
                    setSelected({
                      answer: "option_one",
                      value: data[currentQuestion]?.option_one,
                    });
                  }}
                >
                  <p> {data[currentQuestion]?.option_one}</p>
                </div>
                <div
                  className={
                    selected.answer === "option_two"
                      ? "col-6 option option-selected"
                      : "col-6 option"
                  }
                  onClick={() => {
                    setSelected({
                      answer: "option_two",
                      value: data[currentQuestion]?.option_two,
                    });
                  }}
                >
                  <p> {data[currentQuestion]?.option_two}</p>
                </div>
                <div
                  className={
                    selected.answer === "option_three"
                      ? "col-6 option option-selected"
                      : "col-6 option"
                  }
                  onClick={() => {
                    setSelected({
                      answer: "option_three",
                      value: data[currentQuestion]?.option_three,
                    });
                  }}
                >
                  <p> {data[currentQuestion]?.option_three}</p>
                </div>
                {/* <div
                  className={
                    selected.answer === data[currentQuestion]?.answer
                      ? "col-6 option option-selected"
                      : "col-6 option"
                  }
                  onClick={() => {
                    setSelected({
                      status: true,
                      answer: data[currentQuestion]?.answer,
                    });
                  }}
                >
                  <p> {data[currentQuestion]?.answer}</p>
                </div> */}
              </div>

              <button
                onClick={setAnswers}
                className="btn btn-primary mt-4"
                disabled={data?.length === answerList.length}
              >
                Continue
              </button>
            </>
          ) : (
            <>
              <center>
                <h1>Quiz is empty</h1>
              </center>
            </>
          )}
        </Card>
      </div>

      <Correct
        question={data[currentQuestion]?.question}
        answer={answer}
        show={showCorrectModal}
        nextQuestion={nextQuestion}
        onHide={() => setShowCorrectModal(false)}
      />
      <InCorrect
        question={data[currentQuestion]?.question}
        answer={answer}
        show={showInCorrectModal}
        nextQuestion={nextQuestion}
        onHide={() => setShowInCorrectModal(false)}
      />
      <Result
        show={showResult}
        goHome={goHome}
        minute={minutes.toString().padStart(2, "0")}
        seconds={seconds.toString().padStart(2, "0")}
        result={((correct / questions.length) * 100).toFixed(2)}
        shareResult={shareResult}
        onHide={() => setShowResult(false)}
      />
    </div>
  );
};

export default TimedMode;
