import Page from "../../Components/pages/page";
import SendFundz from "./send-funds";
import DetailCard from "../../Components/cards/detail-card";
import { trending, dummy_news, article } from "../../datas";
import "./tnews.css";
import { likes, Newsdata } from "../../interface/news.interface";
import { useEffect, useState } from "react";
import { sendLikes, singleNews, sendDisLikes } from "../../services/news";
import { isLoggedIn } from "../../services/auth";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const NewsDetail = () => {
  const [data, setData] = useState<Newsdata>({
    text: "",
    title: "",
    date: "",
    image: "",
    comments: [],
    content: "",
    image_url: "",
    liked: false,
    likePost: "",
    is_deleted: false,
    loved_by: "",
    seen_by: "",
    active: false,
    createdAt: "",
    created_by: "",
    created_no: "",
    created_on: "",
    deleted_on: "",
    slug: "",
    updatedAt: "",
    video_url: "",
    __v: "",
    _id: "",
    message: "",
  });

  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  //const id = queryParams.get("news-id");
  //const type = queryParams.get("type");
  const { id, newsTitle } = useParams();
  const [like, setLike] = useState<likes>({
    publishId: id,
    action: "like",
  });
  const [dislike, setDislike] = useState<likes>({
    publishId: id,
    action: "dislike",
  });

  const loadData = async () => {
    // if (type === "Trending") {
    //   const trend = trending.find((i: any) => i._id === id);
    //   setData(trend);
    // } else if (type === "News") {
    //   const newd = dummy_news.find((i: any) => i._id === id);
    //   if (newd) {
    //     setData(newd);
    //   } else {
    const newd = await singleNews(id);
    setData(newd.data);
    //   }
    // } else if (type === "Articles") {
    //   const articled = article.find((i: any) => i._id === id);
    //   setData(articled);
    // }
  };

  const likePost = async () => {
    const status = await isLoggedIn();
    if (!status)
      return toast.error("You are not logged in", {
        position: toast.POSITION.TOP_CENTER,
      });
    setLiked(true);
    return await sendLikes(like);
  };
  const dislikePost = async () => {
    const status = await isLoggedIn();
    if (!status)
      return toast.error("You are not logged in", {
        position: toast.POSITION.TOP_CENTER,
      });
    setDisliked(true);
    return await sendDisLikes(dislike);
  };

  useEffect(() => {
    loadData();
  }, [data]);

  return (
    <Page>
      <div className="tnews">
        <div className="tab">
          <div className="m-auto inner"></div>
        </div>
        <div className="row news-list">
          <h2 className="news-title mb-4 mt-4">{data?.title}</h2>
          <div className="col-12 d-flex">
            <DetailCard
              liked={liked}
              disliked={disliked}
              text={data?.text}
              title={data?.title}
              date={data?.date}
              image={data?.image}
              comments={data?.comments}
              likePost={likePost}
              dislikePost={dislikePost}
              active={data?.active}
              content={data?.content}
              createdAt={data?.createdAt}
              created_by={data?.created_by}
              created_no={data?.created_no}
              created_on={data?.created_on}
              deleted_on={undefined}
              image_url={data?.image_url}
              is_deleted={data?.is_deleted}
              loved_by={data?.loved_by}
              seen_by={0}
              slug={undefined}
              updatedAt={data?.updatedAt}
              video_url={undefined}
              __v={0}
              _id={data?._id}
              message={undefined}
            />
          </div>
        </div>
      </div>
      <div className="send-funds ">
        <div className="spaces"></div>
        <SendFundz />
      </div>
    </Page>
  );
};

export default NewsDetail;
