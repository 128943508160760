import "./login.css";
import Input from "../../Components/forms/form";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { loginPayload } from "../../interface/news.interface";
import { isLoggedIn, logInRequest } from "../../services/auth";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const Login = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState<loginPayload>({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  //login request
  const logIn = async () => {
    setLoading(true);
    const initiateLogin = await logInRequest(details);
    if (initiateLogin === "success") {
      setLoading(false);
      navigate("/tnews");
    } else {
      setLoading(false);
      return initiateLogin;
    }
  };

  //check if a use is logged in
  const isActiveUser = async () => {
    const status = await isLoggedIn();
    if (status) navigate("/tnews");
  };

  useEffect(() => {
    isActiveUser();
  });
  return (
    <div className="login">
      {loading && (
        <>
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
      <ToastContainer />
      <img
        alt="img"
        src="/assets/images/tbook.png"
        width="47.78"
        height="45"
        className="border-10 "
      />
      <h3 className="mt-4">Sign In</h3>
      <p className="mb-3 text-secondary">Welcome back to your account.</p>
      <Input
        showNaira={false}
        showQuestion={false}
        id="email"
        placeholder="Email"
        type="email"
        onChange={(e: any) =>
          setDetails((prevDetails) => ({
            ...prevDetails,
            email: e.target.value,
          }))
        }
        ref={undefined}
        value={details.email}
        required={false}
      />
      <Input
        showNaira={false}
        showQuestion={false}
        placeholder="Password"
        type="password"
        id="password"
        onChange={(e: any) =>
          setDetails((prevDetails) => ({
            ...prevDetails,
            password: e.target.value,
          }))
        }
        ref={undefined}
        value={details.password}
        required={false}
      />
      <div className="d-flex justify-content-between mt-4">
        <div className="d-flex w-50">
          <input
            className="form-check-input shadow-none"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <p className="ml-5">Remember me</p>
        </div>
        <Link className="text-primary forgot" to="/">
          Forgot Password?
        </Link>
      </div>
      <button className="btn btn-primary mt-4" onClick={() => logIn()}>
        Login
      </button>
      <div className="text-center mt-3">
        <p>
          <span className="text-secondary">New here? </span>
          <Link className="auth-link" to="/sign-up">
            Create an account
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
