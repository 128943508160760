import moment from "moment";
import { dummyNewsInterface } from "../../interface/news.interface";
import { useNavigate } from "react-router-dom";
import "./card.css";
import { FaTrash, FaEdit } from "react-icons/fa";
import { Editor } from "react-draft-wysiwyg";

import { useEffect, useState } from "react";
import { isLoggedIn } from "../../services/auth";

import { Button, Modal, ModalBody, ModalFooter, Input } from "reactstrap";

const Card = ({
  date,
  title,
  text,
  image,
  first,
  _id,
  type,
  toggleModal,
  editModal,
  handleEditModal,
  deleteNews,
  editorState,
  updateNews,
  onChange,
  onEditorStateChange,
  ...restProps
}: dummyNewsInterface) => {
  const [viewAll, setViewAll] = useState(false);
  const [isAdmin, setIsadmin] = useState(false);

  const navigate = useNavigate();
  const goToNews = (e: any) => {
    e.preventDefault();
    navigate({
      pathname: `/news-details/${_id}/${title.replace(/ /g, "-")}`,
      // search: `?news-id=${_id}&title=${title.replace(/ /g, "-")}`,
    });
  };
  const checkStatus = async () => {
    const status = await isLoggedIn();
    if (!status) {
      setIsadmin(false);
    } else {
      setIsadmin(true);
    }
  };

  useEffect(() => {
    checkStatus();
  });

  return (
    <div
      className={first ? "card-top border-0" : "card border-0"}
      {...restProps}
    >
      <div className={first ? "p-2" : "w-100 px-2"}>
        <img className="w-100 m-1 " alt="" src={image || ""} />
      </div>

      <div className="p-3">
        <p className="card-date">{moment(date).format("dddd DD MMMM, YYYY")}</p>
        <h4 className="card-title">{title}</h4>
        <p
          className="card-text"
          dangerouslySetInnerHTML={{
            __html: viewAll ? text : text?.substring(0, 150) + " ...",
          }}
        ></p>
        <a className="float-left mt-3" onClick={goToNews} href="/">
          Read More
        </a>
        {isAdmin ? (
          <div className="float-right d-flex w-25 justify-content-between">
            <p
              onClick={() => {
                handleEditModal({ title, text, image_url: image, _id, type });
              }}
            >
              <FaEdit />
            </p>
            <p onClick={deleteNews}>
              <FaTrash />
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      <Modal
        isOpen={editModal._id === _id}
        toggle={toggleModal}
        size="md"
        onClosed={() => handleEditModal({})}
      >
        <ModalBody>
          <h3 className="mt-4 mb-5">Update News</h3>
          <label>Title</label>
          <Input
            value={editModal.title}
            showNaira={false}
            showQuestion={false}
            placeholder="Select Title"
            type="text"
            onChange={(e: any) => {
              onChange("title", e.target.value);
            }}
            id={"title"}
            required={false}
          />
          <br />
          <img src={editModal.image_url} alt="img" width="100%" height="50%" />
          <br />
          <br />
          <label>Image Url</label>
          <Input
            value={editModal.image_url}
            showNaira={false}
            showQuestion={false}
            placeholder="Image address"
            type="url"
            onChange={(e: any) => {
              onChange("image", e.target.value);
            }}
            id={"image_url"}
            required={false}
          />

          <label className="mt-3">Type</label>
          <select
            className="img-input form-control mb-3 shadow-none"
            onChange={(e: any) => {
              onChange("type", e.target.value);
            }}
            value={editModal.type}
          >
            <option>News</option>
            <option>Trending</option>
            <option>Article</option>
          </select>

          <Editor
            placeholder="Type in news....."
            wrapperClassName="wrapper-class border "
            editorClassName="editor-class m-2"
            toolbarClassName="toolbar-class text-dark"
            editorState={editorState}
            toolbar={{
              options: [
                "inline",
                "fontSize",
                "fontFamily",
                "textAlign",
                "list",
              ],
              inline: {
                options: ["italic", "bold"],
              },
              blockType: {
                className: "demo-option-custom-wide",
                dropdownClassName: "demo-dropdown-custom",
              },
              fontSize: { className: "demo-option-custom-medium" },
            }}
            onEditorStateChange={(editorState) => {
              onEditorStateChange(editorState);
            }}
          />
          <div className="d-flex m-4">
            <button className="btn btn-secondary w-50" onClick={toggleModal}>
              Cancel
            </button>
            <button className="btn btn-primary w-50 h-75 " onClick={updateNews}>
              Upload
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Card;
