import { Form } from "react-bootstrap";
import "./form.css";

interface formProps {
  onChange: () => void;
  type: string;
  placeholder: string;
  className: string;
}
const SearchInput = ({
  onChange,
  type,
  placeholder,
  className,
  ...restProps
}: formProps) => {
  return (
    <Form.Control
      className={className + " search shadow-none"}
      placeholder={placeholder}
      onChange={onChange}
      type={type || "string"}
      {...restProps}
    />
  );
};

export default SearchInput;
